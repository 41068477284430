import { DeepPartial } from '@empathybroker/ebx-gluon';

export const es: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Ordenar'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtrar</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrar</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Borrar todo'
  },
  history: {
    clear: 'Borrar recientes'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Lamentablemente no hemos encontrado nada que encaje con tu búsqueda</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Quizás estés interesado'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">No hay resultados para </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, tal vez puedan interesarte:</span>`,
    viewAllHTML: 'Ver {totalResults} resultados</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Resultados de la búsqueda </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Borrar',
    inputPlaceholderAlternativesHTML: [
      'Buscar productos'
    ]
  },
  sort: {
    title: 'Ordenar'
  }
};
