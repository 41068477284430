import { ResultPrice } from '@empathy/search-types';
import { RootStoreState } from '@empathybroker/ebx-gluon';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { OyshoPricePercentageTarget, OyshoResult } from '../adapter/oysho.models';

@Component({
  computed: {
    ...mapState({
      isPreWarming: (state: Partial<RootStoreState>) =>
        state.config.dynamic.prewarming,
      priceColor: (state: Partial<RootStoreState>) =>
        state.config.dynamic.priceColor,
      triplePricePercentage: (state: Partial<RootStoreState>) =>
          state.config.dynamic.triplePricePercentage,
      enabledTriplePrice: (state: Partial<RootStoreState>) =>
          state.config.dynamic.enabledTriplePrice,
    }),
  },
})
export default class PriceMixin extends Vue {
  @Prop({ required: true })
  public item!: OyshoResult;

  protected isPreWarming!: boolean;
  protected priceColor!: string;
  protected triplePricePercentage!: OyshoPricePercentageTarget;

  protected enabledTriplePrice!: boolean;

  /* Rate to convert euros to kunas defined by the European Union. */
  protected rate = 7.5345;

  get discount(): number {
    return Math.floor(
      100 - (this.item.price.value / ((this.item.price as ResultPrice).previous ?? this.item.price.originalValue)) * 100
    );
  }

  get hasPreWarming(): boolean {
    return (
      this.isPreWarming &&
      !!(this.item.preWarming && Object.keys(this.item.preWarming).length)
    );
  }

  get showDiscount(): boolean {
    return this.item.price.hasDiscount && !this.hasPreWarming;
  }

  get discountStyles(): Partial<CSSStyleDeclaration> {
    return {
      backgroundColor: this.priceColor,
      padding: '4px',
    };
  }

  get price(): number {
    return this.item.price.hasDiscount
      ? this.item.price.originalValue
      : this.item.price.value;
  }

  /* Detect if it is on croatia site */
  get isCroatia(): boolean {
    return window.inditex?.iStoreJSON?.countryCode === 'HR';
  }

  /* Converts the price from euros to kunas, replace the euro symbol with the kunas one. */
  protected convertPrice(price: number) {
    const { currencyCode, currencySymbolHtml }: { currencyCode: string, currencySymbolHtml: string } =
        window.inditex.iStoreJSON.details.locale as any;
    return `(${this.$options.filters
        .currency(currencyCode === 'HRK' ? price / this.rate : price * this.rate)
        .replace(currencySymbolHtml.trim(), currencyCode === 'HRK' ? '€' : 'Kn')})`;
  }

  protected get showTriplePrice(): boolean {
    const { hasDiscount, originalValue, previous, value } = this.item
        .price as ResultPrice;
    const firstSale = originalValue > value;
    const secondSale = originalValue > previous;
    return hasDiscount && firstSale && secondSale && this.enabledTriplePrice;
  }

  protected get discountPercentage(): string {
    const priceTarget =
        this.triplePricePercentage === 'original'
            ? this.item.price.originalValue
            : (this.item.price as ResultPrice).previous;
    const discount = this.roundDiscount(
        (this.item.price.value / priceTarget) * 100
    );
    return discount && !isNaN(discount) ? `-${discount}%*` : '';
  }
  protected roundDiscount(discount: number): number {
    return 100 - Math.round(discount);
  }

  protected get showTriplePricePercentage(): boolean {
    return (
        this.showTriplePrice &&
        this.triplePricePercentage &&
        this.triplePricePercentage !== 'disabled'
    );
  }
}
