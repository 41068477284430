/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const sk: DeepPartial<Messages> = {
  close: {
    buttonText: ''
  },
  columnsPicker: {
    title: 'Zobraziť'
  },
  custom: {
    mobileFacetsHeader: 'Filtre',
    headerTitle: 'Výsledky',
    comingSoon: 'už čoskoro',
    facetsTitle: 'Filtrovať',
    closeFacets: 'Zatvoriť',
    footerButtonOverlay: 'Použiť',
    priceDisclaimer: '*Zľava sa odčíta zo sezónnej ceny'
  },
  facets: {
    clearFilters: 'Všetko vymazať',
    defaultRange: {
      lessHTML: '< {max}',
      betweenHTML: '{min} - {max}',
      fromHTML: '> {min}'
    },
    defaultDynamicRange: {
      clear: '×',
      filter: '→'
    },
    showLess: 'Menej výsledkov',
    showMore: 'Zobraziť viac filtrov ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filtrovať</span>' +
          '<span class="ebx-filters-text__overlay">Prijať</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrovať</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      },
      groups: {}
    },
    titles: {
      hierarchical_category: 'Kategórie',
      eb_colors_facet: 'Farba',
      size_facet: 'Veľkosť'
    },
    values: {}
  },
  history: {
    title: 'Nedávne vyhľadávania',
    enable: 'Aktivovať',
    disable: 'Deaktivovať',
    clear: 'Vymazať'
  },
  identifierSuggestions: {
    title: ''
  },
  nextQueriesSuggestions: {
    title: 'Mohlo by ťa zaujímať'
  },
  nextQueries: {
    titleHTML: 'Mohlo by ťa zaujímať',
    nextQuery: {
      titleHTML: 'Iní zákazníci hľadajú: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Zobraziť viac'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Bohužiaľ, nenašli sme nič, čo by vyhovovalo vyhľadávaniu </span><span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again">, ukážeme ti, čo sa v súčasnosti najviac vyhľadáva:</span>'
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Žiadne výsledky pre </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, možno ťa zaujme niečo z tohto:</span>',
    viewAllHTML: 'Zobraziť všetko <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Zobraziť podobné výsledky</span>`
  },
  promotedLabel: '',
  popularSearches: {
    title: 'Obľúbené vyhľadávania',
    inCategory: 'v'
  },
  recommendations: {
    title: 'Mohlo by ťa zaujímať',
    showAll: 'Zobraziť všetko'
  },
  results: {
    addToCart: 'Pridať',
    identifierTitle: ''
  },
  resultsSuggestions: {
    title: 'Najvyhľadávanejší tovar'
  },
  resultsFoundHTML: 'Výsledky vyhľadávania. {resultsNumber} výsledky',
  search: {
    inputPlaceholderAlternativesHTML: ['Hľadať produkty']
  },
  selectedFilters: {
    title: 'Vybrané filtre ({count})'
  },
  sort: {
    titleHTML: 'Zoradiť',
    sortTitles: {
      default: 'Relevantnosti',
      priceAsc: 'Cena vzostupne',
      priceDesc: 'Cena klesá'
    }
  },
  spellcheck: 'Nenašli sa žiadne výsledky pre \'{query}\'. Ukazujeme ti výsledky pre \'{spellcheck}\'',
  suggestions: {
    title: 'Najvyhľadávanejšie',
    inCategory: 'v'
  }
};
