/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const cs: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Zobrazit' },
  custom: {
    mobileFacetsHeader: 'Filtry',
    headerTitle: 'Výsledky',
    comingSoon: 'již brzy',
    facetsTitle: 'Filtrovat',
    closeFacets: 'Zavřít',
    footerButtonOverlay: 'Aplikovat',
    priceDisclaimer: '*Sleva uplatněná na sezónní cenu'
  },
  facets: {
    clearFilters: 'Smazat vše',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Zobrazit méně',
    showMore: 'Zobrazit více filtrů ({filtersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Kategorie', eb_colors_facet: 'Barva', size_facet: 'Velikost' }
  },
  history: { title: 'Nedávná vyhledávání', enable: 'Aktivovat', disable: 'Deaktivovat', clear: 'Vyčistit' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Může vás zaujmout' },
  nextQueries: {
    titleHTML: 'Mohlo by vás zajímat',
    nextQuery: {
      titleHTML: 'Ostatní zákazníci hledali: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Zobrazit více'
    }
  },
  noResults: { messageHTML: '<span class="ebx-no-results__message-apologies">Nenašli jsme bohužel nic, co by odpovídalo vašemu vyhledávání „</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">, místo toho vám ukážeme, co dnes bylo</span>' },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Pro </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again"> nebyly nalezeny žádné výsledky, mohlo by vás ale zajímat:</span>',
    viewAllHTML: 'Zobrazit vše {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Zobrazit podobné výsledky</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Populární vyhledávání', inCategory: 'v' },
  recommendations: { title: 'Třeba vás bude zajímat', showAll: 'Zobrazit vše' },
  results: { addToCart: 'Přidat', identifierTitle: '' },
  resultsSuggestions: { title: 'Nejvyhledávanější produkty' },
  resultsFoundHTML: 'Výsledky vyhledávání. {resultsNumber} výsledků',
  search: { inputPlaceholderAlternativesHTML: ['Vyhledat produkty'] },
  selectedFilters: { title: 'Zvolené filtry ({count})' },
  sort: {
    titleHTML: 'Seřadit',
    sortTitles: { default: 'Relevantnost', priceAsc: 'Cena vzestupně', priceDesc: 'Cena sestupně' }
  },
  spellcheck: 'Pro \'{query}\' jsme nenašli žádné výsledky. Zobrazujeme ti výsledky pro \'{spellcheck}\'',
  suggestions: { title: 'Nejvyhledávanější', inCategory: 'v' }
};
