import { DeepPartial, ResultsGridConfig } from '@empathybroker/ebx-gluon';

export const DESKTOP_RESULTS_GRID_CONFIG: DeepPartial<ResultsGridConfig> = {
  components: {
    grid: 'OyshoGrid'
  },
  grid: {
    columns: 4
  }
};

export const MOBILE_RESULTS_GRID_CONFIG: DeepPartial<ResultsGridConfig> = {
  components: {
    grid: 'OyshoGrid'
  },
};
