import { CUSTOM_GETTERS } from '@/config/store.config';
import {
  COMPONENTS,
  DeepPartial,
  DEFAULT_EMPATHIZE_LAYOUT_CONFIG,
  EmpathizeConfig,
  EVENTS,
  SUBMODULES_PATHS
} from '@empathybroker/ebx-gluon';

export const MOBILE_EMPATHIZE_CONFIG: DeepPartial<EmpathizeConfig> = {
  layout: {
    noQuery: [
      [
        DEFAULT_EMPATHIZE_LAYOUT_CONFIG.nextQueriesSuggestions,
        {
          component: COMPONENTS.SMART.PopularSearches,
          getter: `${ SUBMODULES_PATHS.empathize }/${ CUSTOM_GETTERS.popularSearchesIfNextQueries }`,
          itemSelectedEvent: EVENTS.PopularSearchSelected,
          showWhenEmpty: false
        },
        {
          component: COMPONENTS.SMART.History,
          getter: `${ SUBMODULES_PATHS.empathize }/${ CUSTOM_GETTERS.historyIfNextQueries }`,
          itemSelectedEvent: EVENTS.EmpathizeElementSelected,
          showWhenEmpty: false
        }
      ]
    ],
    query: [
      [
        DEFAULT_EMPATHIZE_LAYOUT_CONFIG.suggestions
      ]
    ]
  },
  searchBoxSelector: '.ebx-empathy-x__header'
};

export const DESKTOP_EMPATHIZE_CONFIG: DeepPartial<EmpathizeConfig> = {
  components: {
    panel: COMPONENTS.PANELS.BlockEmpathizePanel
  },

  searchBoxSelector: '.ebx-search-box',

};
