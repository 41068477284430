import { AppBuilder, EVENTS, MUTATIONS_KEYS, Wire, WIRES } from '@empathybroker/ebx-gluon';

const updateUrl: Wire<void> = (observable, { store }) => {
  observable.subscribe(() => { store.commit(MUTATIONS_KEYS.url.setURL, location.href); });
};

export const WIRING_CONFIG: (appBuilder: AppBuilder) => void = appBuilder => {
  appBuilder
    .removeWireFromEvent(WIRES.loadResultId, EVENTS.ProductPageDetected)
    .removeWireFromEvent(WIRES.moveResultToSessionStorage, EVENTS.ProductPageDetected)
    .addWireToEventBeforeWire(WIRES.loadResultId, EVENTS.Add2CartOnProductPageClicked, WIRES.trackAdd2cartOnProductPage)
    .addWireToEventBeforeWire(WIRES.moveResultToSessionStorage, EVENTS.Add2CartOnProductPageClicked, WIRES.trackAdd2cartOnProductPage)
    // Fix replacing URL issues due to Oysho being an SPA
    .addWireToEventBeforeWire(updateUrl, EVENTS.Search, WIRES.pushURL)
    .addWireToEventBeforeWire(updateUrl, EVENTS.QueryCleared, WIRES.pushURL)
    .addWireToEventBeforeWire(updateUrl, EVENTS.ScrollStopped, WIRES.replaceURL)
    .addWireToEventBeforeWire(updateUrl, EVENTS.EmpathyXCreated, WIRES.loadPreselectedFilters)
    .addWireToEventBeforeWire(updateUrl, EVENTS.CloseEmpathyX, WIRES.disableOpenFinished)
    .addWireToEventBeforeWire(updateUrl, EVENTS.FirstGridItemIdInViewChanged, WIRES.replaceURL)
    // Inverting WIRES.trackQuery and WIRES.redirectIfDirect order in EVENTS.SearchSuccess to fix query tagging when redirecting.
    .removeWireFromEvent(WIRES.trackQuery, EVENTS.SearchSuccess)
    .addWireToEventBeforeWire(WIRES.trackQuery, EVENTS.SearchSuccess, WIRES.redirectIfDirect);
};
