import { COMPONENTS, DeepPartial, ResultComponentsConfig } from '@empathybroker/ebx-gluon';

export const MOBILE_RESULT_CONFIG: DeepPartial<ResultComponentsConfig> = {
  link: {
    children: {
      image: {
        children: {
          brokenImage: {
            component: 'OyshoFallbackImage'
          }
        }
      },
      colors: {
        component: 'OyshoResultColors',
        order: 0
      },
      price: {
        component: 'OyshoResultsPrice',
        order: 1
      },
      preWarming: {
        component: 'OyshoResultPreWarming',
        order: 2
      }
    }
  }
};

export const DESKTOP_RESULT_CONFIG: DeepPartial<ResultComponentsConfig> = {
  link: {
    children: {
      price: null,
      title: null,
      colors: null,
      preWarming: null
    }
  },
  addToCart: {
    component: 'OyshoAddToCart'
  },
  titleLink: {
    component: COMPONENTS.PURE.ResultLink,
    data: {
      staticClass: 'ebx-result-title-link'
    },
    children: {
      container: {
        component: 'div',
        data: {
          staticClass: 'ebx-result-container'
        },
        children: {
          title: {
            component: COMPONENTS.PURE.ResultTitle
          },
          price: {
            component: 'OyshoResultsPrice'
          },
        }
      },
      preWarming: {
        component: 'OyshoResultPreWarming'
      },
    }
  },
  colorSelector: {
    component: 'OyshoColorSelector'
  }
};

export const IDENTIFIER_RESULT_CONFIG: DeepPartial<ResultComponentsConfig> = {
  link: {
    children: {
      image: null,
      price: null,
      colors: null,
      preWarming: null,
      title: {
        component: COMPONENTS.PURE.ResultTitle,
        order: 2
      },
    }
  },
  colorSelector: null,
  titleLink: null,
  addToCart: null
};
