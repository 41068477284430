import { COMPONENTS, DeepPartial, SearchBoxConfig } from '@empathybroker/ebx-gluon';

export const MOBILE_SEARCHBOX_CONFIG: DeepPartial<SearchBoxConfig> = {
  autofocus: false,
  instantSearch: {
    enabled: true
  },
  components: {
    icon: null
  }

};

export const DESKTOP_SEARCHBOX_CONFIG: DeepPartial<SearchBoxConfig> = {
  components: {
    icon: COMPONENTS.PURE.SearchIcon,
    extra: [COMPONENTS.PURE.DefaultClose, COMPONENTS.SMART.SelectedRelatedTags]
  }
};
