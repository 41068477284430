/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const ca: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Veure' },
  custom: {
    mobileFacetsHeader: 'Filtres',
    headerTitle: 'Resultats',
    comingSoon: 'molt aviat',
    facetsTitle: 'Filtra',
    closeFacets: 'Tanca',
    footerButtonOverlay: 'Aplica',
    priceDisclaimer: '*Descompte aplicat sobre preu de temporada',
    colors: {
      Yellow: 'GROC',
      White: 'BLANC',
      Teal: 'TURQUESA',
      Red: 'VERMELL',
      Purple: 'PORPRA',
      Pink: 'ROSA',
      Orange: 'TARONJA',
      Olive: 'OLIVA',
      Navy: 'MARÍ',
      Maroon: 'GRANAT',
      Magenta: 'FÚCSIA',
      Green: 'VERD',
      Gray: 'GRIS',
      Brown: 'MARRÓ',
      Blue: 'BLAU',
      Black: 'NEGRE',
      Beige: 'BEIX'
    }
  },
  facets: {
    clearFilters: 'Esborra-ho tot',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Mostra menys',
    showMore: 'Mostra més filtres ({filtersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Categories', eb_colors_facet: 'Color', size_facet: 'Talla' }
  },
  history: {
    title: 'Cerques recents',
    enable: 'Activar',
    disable: 'Desactiva',
    clear: 'Neteja'
  },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Et pot interessar' },
  nextQueries: {
    titleHTML: 'Et podria interessar',
    nextQuery: {
      titleHTML: 'Altres clients han cercat: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Veure més'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Ens sap greu, no hem trobat res que encaixi amb la teva cerca </span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">, t\'ensenyem el més cercat avui:</span>'
  },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Ho sentim, no hem pogut trobar resultats per a la recerca:</span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, però hem trobat aquests altres:</span>',
    viewAllHTML: 'Veure tots {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Veure resultats similars</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Cerques populars', inCategory: 'a' },
  recommendations: { title: 'Potser t\'interessa', showAll: 'Veure tots' },
  results: { addToCart: 'Afegir', identifierTitle: '' },
  resultsSuggestions: { title: 'Productes més buscats' },
  resultsFoundHTML: 'Resultats de cerca. {resultsNumber} resultats',
  search: { inputPlaceholderAlternativesHTML: ['Cercar productes'] },
  selectedFilters: { title: 'Filtres seleccionats ({count})' },
  sort: {
    titleHTML: 'Ordenar',
    sortTitles: {
      default: 'rellevància',
      riceAsc: 'Preu asc',
      priceDesc: 'Preu desc'
    }
  },
  spellcheck: 'No hem trobat resultats per a \'{query}\'. T\'ensenyem resultats per a \'{spellcheck}\'',
  suggestions: { title: 'El més buscat', inCategory: 'a' }
};
