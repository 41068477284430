/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const sv: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Filter'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtrera</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrera</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Ta bort allt'
  },
  history: {
    clear: 'Rensa'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Tyvärr har vi inte hittat något som matchade din sökning </span><span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again">, vi kommer att visa de mest sökta idag:</span>`,
    recommendations: {
      title: 'Du kanske är intresserad av'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Det finns inga resultat för </span><span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, men kanske dessa kan intressera dig:</span>',
    viewAllHTML: 'Se alla ({totalResults})',
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Sökresultat. </span>&nbsp;<span class="ebx-results-number__count">{resultsNumber}</span> resultat',
  search: {
    clear: 'Radera'
  },
  sort: {
    title: 'Sortera'
  }
};
