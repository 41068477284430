import { DeepPartial } from '@empathybroker/ebx-gluon';

export const en: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Sort'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filter</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Delete all'
  },
  history: {
    clear: 'Clear recent'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Unfortunately we have not found anything that matches your search</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'You might be interested in'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">There are no results for </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, maybe you might be interested in: </span>`,
    viewAllHTML: 'See {totalResults} results</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Search results </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Delete recent'
  },
  sort: {
    title: 'Sort'
  }
};
