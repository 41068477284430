




import { OyshoSize as OyshoSizeModel } from '@/adapter/oysho.models';
import { VueCssClasses } from '@empathybroker/ebx-gluon';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OyshoSize extends Vue {
  @Prop({ required: true })
  size!: OyshoSizeModel;

  get cssClasses(): VueCssClasses {
    return {
      'ebx-oysho-size--is-disabled': this.size.isOutOfStock
    };
  }

  emitClick(event: MouseEvent): void {
    this.$emit('click', this.size, event);
  }
}
