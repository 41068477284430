/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const it: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Ordinare'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtra</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtra</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Cancella tutto'
  },
  history: {
    clear: 'Cancella recenti'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Purtroppo non abbiamo trovato nulla che corrisponda alla tua ricerca</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Potrebbe interessarti'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Non ci sono risultati per </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, forse potresti essere interessato: </span>`,
    viewAllHTML: 'Visualizza {totalResults} risultati</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Risultati di ricerca </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Elimina recenti'
  },
  sort: {
    title: 'Ordinare'
  }
};
