import { COMPONENTS, CoreConfig, DeepPartial, SLOTS } from '@empathybroker/ebx-gluon';

export const MOBILE_CORE_CONFIG: DeepPartial<CoreConfig> = {
  components: {
    HorizontalScrollTypeChecker: {
      component: COMPONENTS.SMART.HorizontalScrollTypeChecker,
      data: {
        slot: SLOTS.template.overlay
      }
    },
    ResultsNumber: {
      data: {
        slot: SLOTS.template.toolbar
      },
      order: 1
    },
    RelatedTags: {
      data: {
        slot: SLOTS.template.toolbar
      },
      order: 2
    },
    ColumnsPicker: null,
    Sort: null,
    EmptySearch: {
      children: {
        Recommendations: {
          component: COMPONENTS.SMART.Recommendations
        }
      }
    },
    ToggleFacetsGroups: {
      component: COMPONENTS.SMART.ToggleFacetsGroups,
      data: {
        slot: SLOTS.template.footer
      }
    },
    ClearSelectedFiltersByGroup: {
      component: COMPONENTS.SMART.ClearSelectedFiltersByGroup,
      data: {
        slot: SLOTS.template.footer,
        props: {
          groupId: 'all-facets'
        }
      }
    }
  }
};

export const DESKTOP_CORE_CONFIG: DeepPartial<CoreConfig> = {
  template: 'GridTemplate',
  components: {
    SearchBox: {
      component: 'OyshoFakeHeader',
      children:
        {
          SearchBox: {
            component: COMPONENTS.SMART.SearchBox
          }
        }
    },
    Sort: {
      component: null
    },
    SortList: {
      component: null
    },
    ToggleFacetsGroups: {
      data: {
        slot: SLOTS.template.toolbar
      },
      order: 3
    },
    ClearSelectedFiltersByGroup: null
  }
};
