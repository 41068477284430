import { COMPONENTS, DeepPartial, NextQueriesConfig } from '@empathybroker/ebx-gluon';

export const MOBILE_NEXT_QUERY_CONFIG: DeepPartial<NextQueriesConfig> = {
  components: {
    nextQuery: {
      grid: COMPONENTS.PANELS.SlidingPanel
    }
  },
  max: 2,
  pageFrequency: 1,
  perLoad: 1,
  results: 4
};

export const DESKTOP_NEXT_QUERY_CONFIG: DeepPartial<NextQueriesConfig> = {
  components: {
    nextQuery: {
      grid: COMPONENTS.PURE.DefaultGrid
    }
  },
  gridColumns: 4,
  pageFrequency: 2
};
