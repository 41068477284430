import { DeepPartial, DynamicConfig } from '@empathybroker/ebx-gluon';

declare module '@empathybroker/ebx-gluon' {
  interface DynamicConfig {
    priceColor?: string;
  }
}

export const DYNAMIC_CONFIG: DeepPartial<DynamicConfig> = {
  endpoints: {
    search: 'https://api{env}.empathybroker.com/search/v1/query/{instance}/searchv3'
  }
};
