/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const de: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Sortieren'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filter</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Alles löschen'
  },
  history: {
    clear: 'Kürzliche Suchanfragen löschen'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Leider haben wir nichts gefunden, was Ihrer Suche entspricht</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Vielleicht interessant für Sie'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Es gibt keine Ergebnisse für </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, Vielleicht interessieren Sie sich dafür: </span>`,
    viewAllHTML: 'Siehe {totalResults} Ergebnisse</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Suchergebnisse </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Zuletzt löschen'
  },
  sort: {
    title: 'Sortieren'
  }
};
