import { COMPONENTS, DeepPartial, SortConfig } from '@empathybroker/ebx-gluon';

export const MOBILE_SORT_CONFIG: DeepPartial<SortConfig> = {
  components: {
    sortIcon: 'OyshoCaretDownIcon',
    sort: COMPONENTS.PURE.SortDropdown,
    panel: null
  },
  iconSlot: 'dropdown-icon',
  sorts: [
    {
      title: 'default',
      value: ''
    },
    {
      title: 'priceAsc',
      value: 'minPriceFloat_{store} asc'
    },
    {
      title: 'priceDesc',
      value: 'minPriceFloat_{store} desc'
    }
  ]
};

export const DESKTOP_SORT_CONFIG: DeepPartial<SortConfig> = {
  components: {
    sortIcon: 'OyshoCaretDownIcon',
    sort: COMPONENTS.PURE.SortToggle,
    panel: COMPONENTS.PANELS.SidePanel
  },
  iconSlot: 'sort-toggle-icon',
  sorts: [
    {
      title: 'default',
      value: ''
    },
    {
      title: 'priceAsc',
      value: 'minPriceFloat_{store} asc'
    },
    {
      title: 'priceDesc',
      value: 'minPriceFloat_{store} desc'
    }
  ]
};
