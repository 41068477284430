/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const el: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Είδος'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Φίλτρο</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Φίλτρο</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Διαγραφή όλων'
  },
  history: {
    clear: 'Διαγραφή προσφάτων'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Δυστυχώς, δεν βρήκαμε κάτι που να ταιριάζει με την αναζήτησή σας</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Μπορεί να σας ενδιαφέρει'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Δεν υπάρχουν αποτελέσματα για </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, maybe you might be interested in: </span>`,
    viewAllHTML: 'Δείτε {totalResults} αποτελέσματα</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Αποτελέσματα αναζήτησης </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Διαγραφή πρόσφατου'
  },
  sort: {
    title: 'Είδος'
  }
};
