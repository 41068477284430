import {
  EmpathyOyshoImage,
  EmpathyOyshoResult,
  EmpathyOyshoResultColor,
  OyshoResult,
  OyshoResultColor,
  OyshoSize,
  PreWarming
} from '@/adapter/oysho.models';
import { MapResponse } from '@empathy/search-adapter';
import { ResultPrice } from '@empathy/search-types';
import {
  container,
  DEPENDENCIES,
  RootStoreState
} from '@empathybroker/ebx-gluon';
import { Store } from 'vuex';

const enum ImageSize {
  fullSize = 1,
  extraLarge = 2,
  large = 3,
  medium = 4,
  small = 5
}

const enum ImageKind {
  modelZoom = 1,
  modelFront = 2,
  color = 3,
  productFront = 4,
  productBack = 5,
  modelBack = 6
}

const CHINA_STORE = 65009628;

export const mapOyshoResult: MapResponse<EmpathyOyshoResult, OyshoResult> = (
  rawResult,
  result
) => {
  const price = mapPrice(rawResult);

  return Object.assign<OyshoResult, Partial<OyshoResult>>(result, {
    images: [rawResult.urlSearch],
    price,
    comingSoon: !!rawResult.comingSoon,
    selectedColor: rawResult.colorId,
    colors: mapResultColors(result.url, rawResult),
    url: generateColorUrl(result.url, rawResult.colorId),
    preWarming: generatePreWarming(rawResult.prewarming, price)
  });
};

function generatePreWarming(preWarming: PreWarming, { originalValue, value }: ResultPrice): PreWarming | undefined {
  if (hasValue(preWarming)) {
    const { color, discount, price, text } = preWarming;
    const maxPrice = originalValue || value;
    const totalDiscount = Math.floor(100 - Number.parseFloat(price) / maxPrice * 100).toString();

    return {
      color,
      discount,
      price,
      text: text ? text.replace('[[percentageDiscount]]', totalDiscount) : ''
    };
  }

  return undefined;
}

export function getImageUrl(
  image: EmpathyOyshoImage,
  { kind = ImageKind.modelZoom, size = ImageSize.large }: ImageUrlOptions = {}
): string {
  return `${getImageUrlDomain()}/6/photos2${
    image.url
  }_${kind}_1_${size}.jpg?t=${image.timestamp}`;
}

function getImageUrlDomain(): string {
  const rootStore: Store<RootStoreState> = container.get(DEPENDENCIES.Store);
  const store = Number(rootStore.state.config.dynamic.store);
  return store === CHINA_STORE
    ? `https://static.oysho.cn`
    : `https://static.oysho.net`;
}

function mapPrice(rawResult: EmpathyOyshoResultPriceData): ResultPrice {
  const value = Number.parseFloat(rawResult.maxPrice);
  const previous = Number.parseFloat(rawResult.maxOldPrice) || value;
  const originalValue = Number.parseFloat(rawResult.maxOriginalPrice) || previous;

  return { value, originalValue, hasDiscount: previous > value, previous };
}

function mapResultColors(
  baseUrl: string,
  rawResult: EmpathyOyshoResult
): OyshoResultColor[] {
  return [extractColorFromResult(rawResult), ...(rawResult.otherColors ?? [])]
    .filter(
      colorOption =>
        colorOption.sizesIds?.length > 0 && colorOption.name?.length > 0
    ) // We have spotted some variants that come without `sizesIds`. Oysho hides them
    .map(colorOption => ({
      color: {
        id: colorOption.id,
        name: colorOption.name[0],
        image: getImageUrl(colorOption.img, {
          kind: ImageKind.color,
          size: ImageSize.small
        })
      },
      identifier: { value: colorOption.eb_sku },
      price: mapPrice(colorOption),
      sizes: mapSizes(colorOption),
      images: [colorOption.urlSearch],
      url: generateColorUrl(baseUrl, colorOption.id)
    }));
}

function extractColorFromResult(
  rawResult: EmpathyOyshoResult
): EmpathyOyshoResultColor {
  return {
    ...rawResult,
    id: rawResult.colorId,
    name: rawResult.color ?? rawResult.eb_colors
  };
}

function mapSizes(colorOption: EmpathyOyshoResultColor): OyshoSize[] {
  return colorOption.sizesIds.map(sizeId => {
    const [, name] = sizeId.split('|');
    return {
      id: sizeId,
      name,
      isOutOfStock: !colorOption.sizes || !colorOption.sizes.includes(name)
    };
  });
}

export function generateColorUrl(baseURL: string, colorId: string): string {
  const url = new URL(baseURL);
  url.searchParams.set('colorId', colorId);
  return url.href;
}

function hasValue(obj: any): boolean {
  return obj !== undefined && Object.keys(obj).length > 0;
}

interface ImageUrlOptions {
  kind?: ImageKind;
  size?: ImageSize;
}

type EmpathyOyshoResultPriceData = Pick<
  EmpathyOyshoResult,
  'minPrice' | 'maxPrice' | 'minOldPrice' | 'maxOldPrice' | 'minOriginalPrice' | 'maxOriginalPrice'
>;
