/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const cs: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Třídit'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtr</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtr</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Smazat všechny'
  },
  history: {
    clear: 'smazat nedávná'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Unfortunately we have not found anything that matches your search</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Třeba vás bude zajímat'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Pro dotaz nejsou k dispozici žádné výsledky </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, možná by vás mohlo zajímat: </span>`,
    viewAllHTML: 'Zobrazit {totalResults} výsledků</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Výsledky vyhledávání </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Smazat poslední'
  },
  sort: {
    title: 'Třídit'
  }
};
