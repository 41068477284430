import { DeepPartial, RelatedTagsConfig } from '@empathybroker/ebx-gluon';

export const MOBILE_RELATED_TAGS_CONFIG: DeepPartial<RelatedTagsConfig> = {
  selectable: true,
  relatedTags: {
    showSelectedValues: true
  }
};

export const DESKTOP_RELATED_TAGS_CONFIG: DeepPartial<RelatedTagsConfig> = {
  selectable: true,
  relatedTags: {
    showSelectedValues: false
  }
};
