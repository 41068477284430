/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const lv: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Filtri'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Piemērot filtrus</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Piemērot filtrus</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Dzēst visu'
  },
  history: {
    clear: 'Dzēst nesenos'
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Diemžēl neesam atraduši rezultātus, kas atbilstu jūsu meklējumam "</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">.", tāpēc rādām šodien vismeklētāko:</span>',
    recommendations: {
      title: 'Varbūt jūs interesēs'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Nerasta rezultatų pagal paiešką „</span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">“, tačiau jus galėtų dominti:</span>',
    viewAllHTML: 'Rodyti viską {totalResults}'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Meklēšanas rezultāti. </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span> rezultāti',
  search: {
    clear: 'Dzēst'
  },
  sort: {
    title: 'Kārtot'
  }
};
