/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const nl: DeepPartial<Messages> = {
  close: {
    buttonText: ''
  },
  columnsPicker: {
    title: 'Bekijken'
  },
  custom: {
    mobileFacetsHeader: 'Filters',
    headerTitle: 'Resultaten',
    comingSoon: 'binnenkort',
    facetsTitle: 'Filter toepassen',
    closeFacets: 'Sluiten',
    footerButtonOverlay: 'Toepassen',
    priceDisclaimer: '* Korting toegepast op seizoensprijs',
    colors: {
      Yellow: 'GEEL',
      White: 'WIT',
      Teal: 'TURKOOIS',
      Red: 'ROOD',
      Purple: 'PURPER',
      Pink: 'ROZE',
      Orange: 'ORANJE',
      Olive: 'OLIJF',
      Navy: 'MARINE',
      Maroon: 'GRANAAT',
      Magenta: 'FUCHSIA',
      Green: 'GROEN',
      Gray: 'GRIJS',
      Brown: 'BRUIN',
      Blue: 'BLAUW',
      Black: 'ZWART',
      Beige: 'BEIGE'
    }
  },
  facets: {
    clearFilters: 'Alles wissen',
    defaultRange: {
      lessHTML: '< {max}',
      betweenHTML: '{min} - {max}',
      fromHTML: '> {min}'
    },
    defaultDynamicRange: {
      clear: '×',
      filter: '→'
    },
    showLess: 'Minder weergeven',
    showMore: 'Meer filters weergeven ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accepteren</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      },
      groups: {}
    },
    titles: {
      hierarchical_category: 'toepassen',
      eb_colors_facet: 'Kleur',
      size_facet: 'Maat'
    },
    values: {}
  },
  history: {
    title: 'Recente zoekopdrachten',
    enable: 'Inschakelen',
    disable: 'Uitschakelen',
    clear: 'Recente zoekopdrachten verwijderen'
  },
  identifierSuggestions: {
    title: ''
  },
  nextQueriesSuggestions: {
    title: 'Misschien vind je dit interessant'
  },
  nextQueries: {
    titleHTML: 'Misschien is dit interessant voor je',
    nextQuery: {
      titleHTML: 'Andere klanten hebben ook gezocht: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Meer bekijken'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Helaas hebben we niets gevonden dat overeenkomt met je zoekopdracht </span> <span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again">. We laten je de meest gezochte artikelen van vandaag zien:</span>'
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Er zijn geen resultaten voor </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">. Misschien vind je dit interessant:</span>',
    viewAllHTML: 'Alles bekijken <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Vergelijkbare resultaten bekijken</span>`
  },
  promotedLabel: '',
  popularSearches: {
    title: 'Populaire zoekopdrachten',
    inCategory: 'in'
  },
  recommendations: {
    title: 'Misschien vind je dit ook leuk',
    showAll: 'Alles bekijken'
  },
  results: {
    addToCart: 'Toevoegen',
    identifierTitle: ''
  },
  resultsSuggestions: {
    title: 'Meest gezochte artikelen'
  },
  resultsFoundHTML: 'Zoekresultaten. {resultsNumber} resultaten',
  search: {
    inputPlaceholderAlternativesHTML: ['Artikelen zoeken']
  },
  selectedFilters: {
    title: 'Geselecteerde filters ({count})'
  },
  sort: {
    titleHTML: 'Sorteren',
    sortTitles: {
      default: 'Relevantie',
      priceAsc: 'Prijs aflopend',
      priceDesc: 'Prijs oplopend'
    }
  },
  spellcheck: 'We hebben geen resultaten gevonden voor "{query}". We laten je de resultaten zien voor "{spellcheck}":',
  suggestions: {
    title: 'Meest gezocht',
    inCategory: 'in'
  }
};
