/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const th: DeepPartial<Messages> = {
  facets: {
    clearFilters: 'ล้างตัวกรอง'
  },
  resultsFoundHTML: '({resultsNumber})',
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">ขออภัยเราไม่พบสิ่งที่ตรงกับการค้นหาของคุณ </span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">.</span>`,
    recommendations: {
      title: 'คุณอาจสนใจสิ่งนี้'
    }
  },
  history: {
    clear: 'ลบล่าสุด'
  },
  recommendations: {
    title: 'คุณอาจสนใจสิ่งนี้'
  }
};
