







import { Components } from '@empathybroker/ebx-gluon';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Sort: Components.Smart.Sort,
    SortList: Components.Smart.SortList
  }
})
export default class OyshoSortList extends Vue {
}
