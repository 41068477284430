/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const th: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'ดู' },
  facets: {
    clearFilters: 'ล้างข้อมูล',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'ดูน้อยลง',
    showMore: 'ดูเพิ่มเติม',
    toggle: {
      default: {
        openHTML: 'ตัวกรอง <span class="ebx-panel-toggle__filters-count">{selectedFiltersCount}</span>',
        closeHTML: 'ยอมรับ'
      }, groups: {}
    },
    titles: { hierarchical_category: 'หมวดหมู่', color_facet: 'สี', size_facet: 'ไซส์' },
    values: {}
  },
  history: { title: 'การค้นหาล่าสุด', enable: 'เปิดใช้งาน', disable: 'ปิดใช้งาน', clear: 'ล้างข้อมูล' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'คุณอาจสนใจสิ่งนี้' },
  nextQueries: {
    nextQuery: {
      titleHTML: 'คุณอาจจะสนใจ: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'ดูเพิ่มเติม'
    }
  },
  noResults: { messageHTML: '<span class="ebx-no-results__message-apologies">ขออภัย เราไม่พบรายการที่ตรงกับคำถามของคุณเลย </span><span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">, เราขอแสดงรายการที่มีการค้นหามากที่สุดในวันนี้ให้คุณ:</span>' },
  emptySearch: { message: 'สินค้าเด่น' },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">ไม่พบผลการค้นหาสำหรับ "</span><span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">", คุณอาจจะสนใจสิ่งนี้: </span>',
    viewAllHTML: 'ดูทั้งหมด',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">ดูผลลัพธ์ที่คล้ายกัน</span>`
  },
  promotedLabel: '',
  resultsFoundHTML: '<span class="ebx-results-number__count">{resultsNumber}</span> ผล',
  search: { inputPlaceholderAlternativesHTML: ['เขียนคำถามของคุณ'] },
  popularSearches: { title: 'การค้นหาที่ใช้บ่อยที่สุด', inCategory: 'ใน' },
  recommendations: { title: 'คุณอาจสนใจสิ่งนี้', showAll: 'ดูทั้่งหมด' },
  results: { addToCart: 'เพิ่ม', identifierTitle: '' },
  resultsSuggestions: { title: 'สินค้าที่มีการค้นหามากที่สุด' },
  selectedFilters: { title: 'ตัวกรองที่เลือก ({count})' },
  sort: {
    titleHTML: 'ความเกี่ยวข้อง',
    sortTitles: { default: 'ราคาจากต่ำไปสูง', priceAsc: 'ราคาจากสูงไปต่ำ', priceDesc: 'ขออภัย ไม่พบผลลัพธ์จากการค้นหาคำว่า X' }
  },
  spellcheck: 'นี่คือผลลัพธ์สำหรับ \'{query}\'.Tเทรนด์ที่ได้รับความนิยมสูงสุด \'{spellcheck}\'',
  suggestions: { title: 'ใน', inCategory: 'ใน' },
  custom: { headerTitle: 'ผลการค้นหา', comingSoon: 'สินค้าที่จะมาถึงเร็ว ๆ นี้', priceDisclaimer: '*มีส่วนลดจากราคาเฉพาะเทศกาล' }
};
