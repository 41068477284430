/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const pl: DeepPartial<Messages> = {
  close: {
    buttonText: ''
  },
  columnsPicker: {
    title: 'Zobacz'
  },
  custom: {
    mobileFacetsHeader: 'Filtry',
    headerTitle: 'Wyniki',
    comingSoon: 'coming soon',
    facetsTitle: 'Filtruj',
    closeFacets: 'Zamknij',
    footerButtonOverlay: 'Zastosuj',
    priceDisclaimer: '* Zniżka jest naliczana od ceny sezonowej',
    colors: {
      Yellow: 'ŻÓŁTY',
      White: 'BIAŁY',
      Teal: 'TURKUSOWY',
      Red: 'CZERWONY',
      Purple: 'PURPUROWY',
      Pink: 'RÓŻOWY',
      Orange: 'POMARAŃCZOWY',
      Olive: 'OLIWKOWY',
      Navy: 'MORSKI',
      Maroon: 'CZERWONE WINO',
      Magenta: 'FUKSJA',
      Green: 'ZIELONY',
      Gray: 'SZARY',
      Brown: 'BRĄZOWY',
      Blue: 'NIEBIESKI',
      Black: 'CZARNY',
      Beige: 'BEŻOWY'
    }
  },
  facets: {
    clearFilters: 'Usuń wszystko',
    defaultRange: {
      lessHTML: '< {max}',
      betweenHTML: '{min} - {max}',
      fromHTML: '> {min}'
    },
    defaultDynamicRange: {
      clear: '×',
      filter: '→'
    },
    showLess: 'Pokaż mniej',
    showMore: 'Pokaż więcej filtrów ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filtruj</span>' +
          '<span class="ebx-filters-text__overlay">Akceptuj</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtruj</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      },
      groups: {}
    },
    titles: {
      hierarchical_category: 'Kategorie',
      eb_colors_facet: 'Kolor',
      size_facet: 'Rozmiar'
    },
    values: {}
  },
  history: {
    title: 'Ostatnie wyszukiwania',
    enable: 'Aktywuj',
    disable: 'Dezaktywuj',
    clear: 'Wyczyść'
  },
  identifierSuggestions: {
    title: ''
  },
  nextQueriesSuggestions: {
    title: 'Może Cię zainteresować'
  },
  nextQueries: {
    titleHTML: 'Może Cię zainteresuje',
    nextQuery: {
      titleHTML: 'Inni klienci szukali: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Zobacz więcej'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">NNiestety nie znaleźliśmy żadnych wyników pasujących do kryteriów Twojego wyszukiwania "</span> <span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again">, pokazujemy najczęściej wyszukiwane dziś:</span>'
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Brak wyników dla </span><span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, być może zainteresuje Cię:</span>',
    viewAllHTML: 'Zobacz wszystko ({totalResults})',
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Zobacz podobne wyniki</span>`
  },
  promotedLabel: '',
  popularSearches: {
    title: 'Popularne wyszukiwania',
    inCategory: 'w'
  },
  recommendations: {
    title: 'Może zainteresuje Cię',
    showAll: 'Zobacz wszystko'
  },
  results: {
    addToCart: 'Dodaj',
    identifierTitle: ''
  },
  resultsSuggestions: {
    title: 'Artykuły najbardziej poszukiwane'
  },
  resultsFoundHTML: 'Wyniki wyszukiwania. {resultsNumber} wyniki',
  search: {
    inputPlaceholderAlternativesHTML: ['Znajdź artykuły']
  },
  selectedFilters: {
    title: 'Wybrane filtry ({count})'
  },
  sort: {
    titleHTML: 'Uporządkuj',
    sortTitles: {
      default: 'Stosowność',
      priceAsc: 'Cena rosnąco',
      priceDesc: 'Cena malejąca'
    }
  },
  spellcheck: 'Brak wyników dla „{query}”. Oto wyniki dla \'{spellcheck}\'',
  suggestions: {
    title: 'Najbardziej poszukiwane',
    inCategory: 'w'
  }
};
