









import { Components, GETTERS_KEYS } from '@empathybroker/ebx-gluon';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    EventButton: Components.Pure.EventButton
  }
})
export default class ApplyFacetsGroup extends Vue {
  get hasSelectedFilters(): boolean {
    return this.$store.getters[GETTERS_KEYS.search.selectedFilters]
        .filter(filter => filter.facet.modelName !== 'hierarchical_category').length > 0;
  }
}
