/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const gl: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Ver' },
  custom: {
    mobileFacetsHeader: 'Filtros',
    headerTitle: 'Resultados',
    comingSoon: 'coming soon',
    facetsTitle: 'Filtrar',
    closeFacets: 'Pechar',
    footerButtonOverlay: 'Aplicar',
    priceDisclaimer: '* Desconto aplicado sobre o prezo de tempada',
    colors: {
      Yellow: 'AMARELO',
      White: 'BRANCO',
      Teal: 'TURQUESA',
      Red: 'VERMELLO',
      Purple: 'PÚRPURA',
      Pink: 'ROSA',
      Orange: 'LARANXA',
      Olive: 'OLIVA',
      Navy: 'MARIÑO',
      Maroon: 'GRANATE',
      Magenta: 'FUCSIA',
      Green: 'VERDE',
      Gray: 'GRIS',
      Brown: 'MARRÓN',
      Blue: 'AZUL',
      Black: 'NEGRO',
      Beige: 'BEIGE'
    }
  },
  facets: {
    clearFilters: 'Borrar todo',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Mostrar menos',
    showMore: 'Mostrar más',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Categorías', eb_colors_facet: 'Cor', size_facet: 'Talla' },
    values: {}
  },
  history: { title: 'Buscas recentes', enable: 'Activar', disable: 'Desactivar', clear: 'Limpiar' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Pódeche interesar' },
  nextQueries: {
    titleHTML: 'Poderíache interesar',
    nextQuery: {
      titleHTML: 'Outros clientes buscaron: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Ver máis'
    }
  },
  noResults: { messageHTML: '<span class="ebx-no-results__message-apologies">Lamentabelmente, non atopamos nada que encaixe coa túa busca "</span><span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">", mostrámosche o máis buscado hoxe:</span>' },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Non hai resultados para </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, se cadra poden interesarche:</span>',
    viewAllHTML: 'Ver todos ({totalResults})',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Ver resultados semellantes</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Buscas Populares', inCategory: 'en' },
  recommendations: { title: 'Poida que esteas interesado', showAll: 'Ver todos' },
  results: { addToCart: 'Engadir', identifierTitle: '' },
  resultsSuggestions: { title: 'Produtos máis buscados' },
  resultsFoundHTML: 'Resultados busca. {resultsNumber} resultados',
  search: { inputPlaceholderAlternativesHTML: ['Buscar produtos'] },
  selectedFilters: { title: 'Filtros seleccionados ({count})' },
  sort: { titleHTML: 'Ordenar', sortTitles: { default: 'Relevancia', priceAsc: 'Prezo asc', priceDesc: 'Prezo desc' } },
  spellcheck: 'Non atopamos resultados para \'{query}\'. Mostrámosche resultados para \'{spellcheck}\'',
  suggestions: { title: 'O máis buscado', inCategory: 'en' }
};
