






import OyshoPriceDisclaimer from '@/components/oysho-price-disclaimer.vue';
import {
  Components,
  DynamicConfig,
  SUBMODULES_PATHS
} from '@empathybroker/ebx-gluon';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  inheritAttrs: false,
  components: {
    OyshoPriceDisclaimer,
    Grid: Components.Pure.DefaultGrid
  },
  computed: {
    ...mapState(SUBMODULES_PATHS.config, ['dynamic'])
  }
})
export default class OyshoGrid extends Vue {
  dynamic: DynamicConfig;

  protected get showTriplePrice(): boolean {
    return this.dynamic.enabledTriplePrice;
  }
}
