












import { OyshoResult, OyshoResultColor } from '@/adapter/oysho.models';
import OyshoColor from '@/components/add-to-cart/oysho-color.vue';
import { EVENTS } from '@empathybroker/ebx-gluon';
import { UpdateResultMutationPayload } from '@empathybroker/ebx-gluon/types/store/search/mutations/update-result.mutation';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    OyshoColor
  }
})
export default class OyshoColorSelector extends Vue {
  @Prop({ required: true })
  item!: OyshoResult;

  private emitResultColorChanged(variant: OyshoResultColor, event: MouseEvent): void {
    /* This is done because when you click on an element, two events have to happen to emit a click, mousedown and mouseup,
      * but how the empathize closed, the mousedown and the mouseup events do not be executed in the same element (because
      * the grid and, it is elements are moved).
      * To avoid that, we prevent the mousedown in the element, to not lose the focus in the empathize, this will cause
      * that the mousedown and the mouseup will be in the same element (click event will be emitted) and after execute the
      * click, we will gain again the focus in the element to close the empathize.
     */
    (event.target as HTMLElement).focus();
    const update: Partial<OyshoResult> = {
      selectedColor: variant.color.id,
      images: variant.images,
      identifier: variant.identifier as any, // Silence error due to incompatible search-types
      price: variant.price,
      url: variant.url
    };
    this.$x.emit<UpdateResultMutationPayload>(EVENTS.UpdateResult, {
      result: this.item as any,
      update
    });
  }
}
