/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const gl: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Ordenar'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtro</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtro</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Elimina todo'
  },
  history: {
    clear: 'Borrar recentes'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Desafortunadamente non atopamos nada que coincida coa túa busca</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Poida que esteas interesado'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Non hai resultados para </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, quizais poida que che interese: </span>`,
    viewAllHTML: 'Ver {totalResults} resultados</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Resultados da busca </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Eliminar recentes'
  },
  sort: {
    title: 'Ordenar'
  }
};
