/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const ru: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Фильтры'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Применить фильтры</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Применить фильтры</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Удалить все'
  },
  history: {
    clear: 'Vymazať'
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">К сожалению, поиск по вашему запросу </span> <span class="ebx-no-results__message-query">«{query}»</span><span class="ebx-no-results__message-try-again"> не дал результатов. Посмотрите самые популярные товары, которые ищут сегодня:</span>',
    recommendations: {
      title: 'Возможно, вас заинтересует'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Нет результатов для </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, возможно, вас заинтересуют:</span>',
    viewAllHTML: 'Посмотреть все <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Результаты поиска. Всего </span>&nbsp;<span class="ebx-results-number__count">{resultsNumber}</span>',
  search: {
    clear: 'Удалить'
  },
  sort: {
    title: 'Сортировать'
  }
};
