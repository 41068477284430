























































































import Component, { mixins } from 'vue-class-component';
import OyshoCroatiaPrice from '../components/oysho-croatia-price.vue';
import PriceMixin from '../components/price.mixin';

@Component({
  components: { OyshoCroatiaPrice }
})
export default class OyshoResultsPrice extends mixins(PriceMixin) {}
