/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const da: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Kolonnevælger' },
  custom: {
    mobileFacetsHeader: 'Filtre',
    headerTitle: 'Resultater',
    comingSoon: 'kommer snart',
    facetsTitle: 'Filtrere',
    closeFacets: 'Logge ud',
    footerButtonOverlay: 'Anvende',
    priceDisclaimer: '*Rabat pålagt sæsonprisen'
  },
  facets: {
    clearFilters: 'Slet alt',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Vis færre',
    showMore: 'Vis flere filtre ({filtersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Kategorier', eb_colors_facet: 'Farve', size_facet: 'Størrelse' },
    values: {}
  },
  history: {
    title: 'Seneste søgninger',
    enable: 'Aktivere',
    disable: 'Deaktivere',
    clear: 'Fjerne'
  },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Anbefalinger til dig' },
  nextQueries: {
    titleHTML: 'Anbefalinger til dig',
    nextQuery: {
      titleHTML: 'Andre kunder har søgt efter: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Se mere'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Vi har desværre ikke fundet nogle varer, der passer til din søgning </span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">. Vi viser dig det mest efterspurgte i dag:</span>'
  },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Der er ikke fundet resultater til </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, men måske synes du om:</span>',
    viewAllHTML: 'Se alle {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Se lignende resultater</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Populære søgninger', inCategory: 'i' },
  recommendations: { title: 'Måske synes du om', showAll: 'Vis alt' },
  results: { addToCart: 'Tilføj', identifierTitle: '' },
  resultsSuggestions: { title: 'De mest eftersøgte varer' },
  resultsFoundHTML: 'Søgningsresultater. {resultsNumber} resultater',
  search: { inputPlaceholderAlternativesHTML: ['Søg efter varer'] },
  selectedFilters: { title: 'Valgte filtre ({count})' },
  spellcheck: '¡Vi har ikke fundet resultater, der passer til \'{query}\'. Vi viser dig resultater, der passer til \'{spellcheck}\'',
  sort: { titleHTML: 'Sortere', sortTitles: { default: 'Relevans', priceAsc: 'Pris stigende', priceDesc: 'Pris faldende' } },
  suggestions: { title: 'De mest søgte varer', inCategory: 'i' }
};
