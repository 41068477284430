












































import Component, { mixins } from 'vue-class-component';
import PriceMixin from '../components/price.mixin';

@Component
export default class OyshoCroatiaPrice extends mixins(PriceMixin) {}
