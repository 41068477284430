/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const hr: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Vidi' },
  custom: {
    mobileFacetsHeader: 'Filtri',
    headerTitle: 'Rezultati',
    comingSoon: 'uskoro',
    facetsTitle: 'Filtriraj',
    closeFacets: 'Zatvori',
    footerButtonOverlay: 'Primijeni',
    priceDisclaimer: '*Popust se primjenjuje na sezonsku cijenu'
  },
  facets: {
    clearFilters: 'Obriši sve',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Prikaži manje',
    showMore: 'Prikaži više filtara ({filtersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Kategorije', eb_colors_facet: 'Boja', size_facet: 'Veličina' },
    values: {}
  },
  history: { title: 'Nedavna pretraživanja', enable: 'Engedélyezze', disable: 'Kikapcsolja', clear: 'Ukloniti sve' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Može vas zanimati' },
  nextQueries: {
    titleHTML: 'Moglo bi vas zanimati',
    nextQuery: {
      titleHTML: 'Drugi su kupci tražili: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Pogledajte više'
    }
  },
  noResults: { messageHTML: '<span class="ebx-no-results__message-apologies">Nažalost, nismo pronašli ništa što bi odgovaralo vašoj pretrazi "</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">". Prikazat ćemo vam što je danas najtraženije:</span>' },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Nema rezultata za </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">. Možda bi vas moglo zanimati:</span>',
    viewAllHTML: 'Vidi sve {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Pogledajte slične rezultate</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Popularne pretrage', inCategory: 'u' },
  recommendations: { title: 'Možda će vas zanimati', showAll: 'Vidi sve' },
  results: { addToCart: 'Dodaj', identifierTitle: '' },
  resultsSuggestions: { title: 'Najtraženiji proizvodi' },
  resultsFoundHTML: 'Rezultati pretrage. {resultsNumber} rezultata',
  search: { inputPlaceholderAlternativesHTML: ['Traži proizvode'] },
  selectedFilters: { title: 'Odabrano filtara ({count})' },
  sort: { titleHTML: 'Razvrstaj', sortTitles: { default: 'Relevantnost', priceAsc: 'Cijena uzlazno', priceDesc: 'Cijena silazno' } },
  spellcheck: 'Nismo pronašli rezultate za \'{query}\'. Prikazujemo vam rezultate za \'{spellcheck}\'',
  suggestions: { title: 'Najtraženije', inCategory: 'u' }
};
