










import { Components } from '@empathybroker/ebx-gluon';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Sort: Components.Smart.Sort,
    PanelToggle: Components.Panels.PanelToggle,
    Icon: Components.Pure.CrossIcon
  }
})
export default class FacetsGroupHeader extends Vue {

}
