/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const ro: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Filtre'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtrare</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrare</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Șterge tot'
  },
  history: {
    clear: 'Ștergere'
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Din păcate, nu am găsit nimic care să se potrivească căutării tale </span><span class="ebx-no-results__message-query">„{query}”</span><span class="ebx-no-results__message-try-again">, îți arătăm cele mai căutate articole de astăzi:</span>',
    recommendations: {
      title: 'Poate te interesează'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Nu există rezultate pentru </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, poate te-ar interesa:</span>',
    viewAllHTML: 'Vizualizare toate <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Search results </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Ștergere'
  },
  sort: {
    title: 'Ordonare'
  }
};
