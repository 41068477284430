/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const el: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Προβολή' },
  custom: {
    mobileFacetsHeader: 'Φίλτρα',
    headerTitle: 'Αποτελέσματα',
    comingSoon: 'coming soon',
    facetsTitle: 'Εφαρμογή φίλτρων',
    closeFacets: 'Κλείσιμο',
    footerButtonOverlay: 'Εφαρμογή',
    priceDisclaimer: '* Η έκπτωση γίνεται στην εποχιακή τιμή',
    colors: {
      Yellow: 'ΚΙΤΡΙΝΟ',
      White: 'ΛΕΥΚΟ',
      Teal: 'ΤΙΡΚΟΥΑΖ',
      Red: 'ΚΟΚΚΙΝΟ',
      Purple: 'ΠΟΡΦΥΡΟ',
      Pink: 'ΡΟΖ',
      Orange: 'ΠΟΡΤΟΚΑΛΙ',
      Olive: 'ΠΡΑΣ.ΕΛ.',
      Navy: 'ΜΠΛΕ ΣΚΟΥΡΟ',
      Maroon: 'ΚΟΚΚΙΝΟ ΓΚΡΕΝΑ',
      Magenta: 'ΦΟΥΞΙΑ',
      Green: 'ΠΡΑΣΙΝΟ',
      Gray: 'ΓΚΡΙ',
      Brown: 'ΚΑΦΕ',
      Blue: 'ΜΠΛΕ',
      Black: 'ΜΑΥΡΟ',
      Beige: 'ΜΠΕΖ'
    }
  },
  facets: {
    clearFilters: 'Διαγραφή όλων',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Προβολή λιγότερων',
    showMore: 'Προβολή περισσότερων φίλτρων ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Κατηγορίες', eb_colors_facet: 'Χρώμα', size_facet: 'μέγεθος' },
    values: {}
  },
  history: { title: 'Πρόσφατες αναζητήσεις', enable: 'Ενεργοποίηση', disable: 'Απενεργοποίηση', clear: 'Εκκαθάριση' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Μπορεί να σας ενδιαφέρει' },
  nextQueries: {
    titleHTML: 'Μπορεί να σας ενδιαφέρει',
    nextQuery: {
      titleHTML: 'Άλλοι πελάτες αναζήτησαν: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Δείτε περισσότερα ({numFound})'
    }
  },
  noResults: { messageHTML: '<span class="ebx-no-results__message-apologies">Δυστυχώς δεν βρέθηκε τίποτα που να αντιστοιχεί στην αναζήτησή σας "</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again"></span>", σας δείχνουμε τις πιο δημοφιλείς αναζητήσεις σήμερα:' },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Δεν υπάρχουν αποτελέσματα για </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, ίσως σας ενδιαφέρουν:</span>',
    viewAllHTML: 'Προβολή όλων {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Δείτε παρόμοια αποτελέσματα</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Δημοφιλείς αναζητήσεις', inCategory: 'σε' },
  recommendations: { title: 'Μπορεί να σας ενδιαφέρει', showAll: 'Προβολή όλων' },
  results: { addToCart: 'Προσθήκη', identifierTitle: '' },
  resultsSuggestions: { title: 'Τα πιο δημοφιλή προϊόντα' },
  resultsFoundHTML: 'Αποτελέσματα αναζήτησης. {resultsNumber} αποτελέσματα',
  search: { inputPlaceholderAlternativesHTML: ['Αναζήτηση προϊόντων'] },
  selectedFilters: { title: 'Επιλεγμένα φίλτρα ({count})' },
  sort: { titleHTML: 'Ταξινόμηση', sortTitles: { default: 'Συνάφεια', priceAsc: 'Τιμή Αύξουσα', priceDesc: 'Τιμή φθίνουσα' } },
  spellcheck: 'Δεν βρήκαμε αποτελέσματα για \'{query}\'. Σας δείχνουμε τα αποτελέσματα για \'{spellcheck}\'',
  suggestions: { title: 'Τα πιο δημοφιλή', inCategory: 'σε' }
};
