/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const uk: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Фільтри'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Фільтрувати</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Фільтрувати</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Стерти все'
  },
  history: {
    clear: 'Стерти останні'
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Шкода, проте ми не знайшли нічого, що відповідає вашому пошуковому запиту</span> «<span class="ebx-no-results__message-query">{query}</span>»<span class="ebx-no-results__message-try-again">. Перегляньте найпопулярніше за сьогодні:</span>',
    recommendations: {
      title: 'Можливо, вам буде цікаво'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Немає результатів за запитом</span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, можливо, вас зацікавить:</span>',
    viewAllHTML: 'Переглянути все <span class="ebx-partial-result__view-all-count">({totalResults})</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Результати пошукового запиту. </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>  результати(-ів)',
  search: {
    clear: 'Стерти'
  },
  sort: {
    title: ' результати(-ів)'
  }
};
