/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const ko: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: '필터'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-count">{selectedFiltersCount}</span><span class="ebx-filters-text">개의 필터</span>',
        openHTML: '<span class="ebx-filters-count">{selectedFiltersCount}</span><span class="ebx-filters-text">개의 필터</span>'
      }
    },
    clearFilters: '모두 지우기'
  },
  history: {
    clear: '최신 지우기'
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">죄송합니다. "</span><span class="ebx-no-results__message-query"> {query}</span><span class="ebx-no-results__message-try-again">"검색과 일치하는 항목을 찾지 못했습니다. 오늘 가장 많이 검색된 항목이 표시됩니다.</span>',
    recommendations: {
      title: '관심 있을 제품'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-apologies">에 대한 결과가 없습니다. </span><span class="ebx-partials__message-try-again">다음과 같은 항목에 관심 있으실 수 있습니다.</span>',
    viewAllHTML: '{totalResults} 개 모두 보기'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">검색 결과. </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span> 결과',
  search: {
    clear: '지우기'
  },
  sort: {
    title: '순서'
  }
};
