import { logger } from '@empathybroker/ebx-gluon';
import * as components from 'barrel:components';
import Vue from 'vue';

export function registerComponents() {
  const componentNames = Object.entries(components).map(([componentName, component]) => {
    Vue.component(componentName, component);
    return componentName;
  });
  logger.info('[Custom Components]', componentNames);
}
