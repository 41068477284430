/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const en: DeepPartial<Messages> = {
  custom: {
    mobileFacetsHeader: 'Filters',
    headerTitle: 'Results',
    comingSoon: 'coming soon',
    facetsTitle: 'Filter',
    closeFacets: 'Close',
    footerButtonOverlay: 'Apply',
    priceDisclaimer: '*Discount applied on season price',
    colors: {
      Yellow: 'YELLOW',
      White: 'WHITE',
      Teal: 'TURQUOISE',
      Red: 'RED',
      Purple: 'PURPLE',
      Pink: 'PINK',
      Orange: 'ORANGE',
      Olive: 'OLIVE',
      Navy: 'NAVY',
      Maroon: 'MAROON',
      Magenta: 'FUCHSIA',
      Green: 'GREEN',
      Gray: 'GREY',
      Brown: 'BROWN',
      Blue: 'BLUE',
      Black: 'BLACK',
      Beige: 'BEIGE'
    }
  },
  facets: {
    showMore: 'Show more filters ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: '<span class="ebx-clear-filters__footer">Delete</span><span class="ebx-clear-filters__overlay">Delete all</span>',
    titles: {
      eb_colors_facet: 'Color'
    }
  },
  history: {
    clear: 'Clear'
  },
  nextQueries: {
    nextQuery: {
      titleHTML: 'Other customers have searched for: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'See more'
    },
    titleHTML: 'You might be interested'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Unfortunately, we haven’t found anything that matches your search </span>
<span class="ebx-no-results__message-query"> \"{query}\"</span><span class="ebx-no-results__message-try-again">, we'll show you today's most searched-for items:</span>`,
    recommendations: {
      title: ''
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">There are no results for </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, you might be interested in this:</span>`,
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    viewAllHTML: 'See {totalResults} results</span>',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">See similar results</span>`,
  },
  results: {
    addToCart: 'Add to'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Search results. </span>&nbsp;<span class="ebx-results-number__count">{resultsNumber} results</span>',
  search: {
    clear: 'Clear',
    inputPlaceholderAlternativesHTML: [
      'Search products:\n'
    ]
  },
  sort: {
    titleHTML: 'Sort by',
    sortTitles: {
      default: 'Relevancy',
      priceAsc: 'Priced from low to high',
      priceDesc: 'Priced from high to low'
    }
  },
  spellcheck: 'We haven\'t found any results for \'{query}\'. Showing results for \'{spellcheck}\''
};
