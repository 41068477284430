/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const ru: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Смотреть' },
  custom: {
    mobileFacetsHeader: 'Фильтры',
    headerTitle: 'Результаты',
    comingSoon: 'скоро',
    facetsTitle: 'Применить фильтр',
    closeFacets: 'Закрыть',
    footerButtonOverlay: 'Применить',
    priceDisclaimer: '*Скидка применена к сезонной цене',
    colors: {
      Yellow: 'ЖЕЛТЫЙ',
      White: 'БЕЛЫЙ',
      Teal: 'БИРЮЗОВЫЙ',
      Red: 'КРАСНЫЙ',
      Purple: 'ПУРПУРНЫЙ',
      Pink: 'РОЗОВЫЙ',
      Orange: 'ОРАНЖЕВЫЙ',
      Olive: 'ОЛИВКОВЫЙ',
      Navy: 'МОРСКОЙ СИНИЙ',
      Maroon: 'ГРАНАТОВЫЙ',
      Magenta: 'ЦВЕТ ФУКСИИ',
      Green: 'ЗЕЛЕНЫЙ',
      Gray: 'СЕРЫЙ',
      Brown: 'КОРИЧНЕВЫЙ',
      Blue: 'СИНИЙ',
      Black: 'ЧЕРНЫЙ',
      Beige: 'БЕЖЕВЫЙ'
    }
  },
  facets: {
    clearFilters: 'Удалить все',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Показать меньше',
    showMore: 'Показать больше фильтров ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Применить фильтры</span>' +
          '<span class="ebx-filters-text__overlay">Принять</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Применить фильтры</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      },
      groups: {}
    },
    titles: {
      hierarchical_category: 'категории',
      eb_colors_facet: 'Цвет',
      size_facet: 'Размер'
    },
    values: {}
  },
  history: {
    title: 'Последние запросы',
    enable: 'Включить',
    disable: 'запрещать',
    clear: 'Vymazať'
  },
  identifierSuggestions: {
    title: ''
  },
  nextQueriesSuggestions: {
    title: 'Вас может заинтересовать'
  },
  nextQueries: {
    titleHTML: 'Вас может заинтересовать',
    nextQuery: {
      titleHTML: 'Результаты поиска других клиентов: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Посмотреть больше'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">К сожалению, поиск по вашему запросу </span> <span class="ebx-no-results__message-query">«{query}»</span><span class="ebx-no-results__message-try-again"> не дал результатов. Посмотрите самые популярные товары, которые ищут сегодня:</span>'
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Нет результатов для </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, возможно, вас заинтересуют:</span>',
    viewAllHTML: 'Посмотреть все <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Смотреть похожие результаты</span>`
  },
  promotedLabel: '',
  popularSearches: {
    title: 'Популярные поисковые запросы',
    inCategory: 'в'
  },
  recommendations: {
    title: 'Возможно, вас заинтересует',
    showAll: 'Посмотреть все'
  },
  results: {
    addToCart: 'Добавить',
    identifierTitle: ''
  },
  resultsSuggestions: {
    title: 'Самые популярные товары'
  },
  resultsFoundHTML: 'Результаты поиска. Всего {resultsNumber}',
  search: {
    inputPlaceholderAlternativesHTML: ['Найти товары']
  },
  selectedFilters: {
    title: 'Выбранные фильтры ({count})'
  },
  sort: {
    titleHTML: 'Сортировать',
    sortTitles: {
      default: 'Актуальность',
      priceAsc: 'Цена по возрастанию',
      priceDesc: 'Цена по убыванию'
    }
  },
  spellcheck: 'Нам не удалось найти результатов по запросу «{query}». Мы покажем результаты для «{spellcheck}»',
  suggestions: {
    title: 'Самое популярное',
    inCategory: 'в'
  }
};
