/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const lt: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Žiūrėti' },
  custom: {
    mobileFacetsHeader: 'Filtrai',
    headerTitle: 'Rezultatai',
    comingSoon: 'jau greitai',
    facetsTitle: 'Filtruoti',
    closeFacets: 'Uždaryti',
    footerButtonOverlay: 'Taikyti',
    priceDisclaimer: '*Nuolaida taikoma sezono kainai'
  },
  facets: {
    clearFilters: 'Ištrinti viską',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Rodyti mažiau',
    showMore: 'Rodyti daugiau filtrų ({filtersNumber})',
    toggle: { default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filtruoti</span>' +
          '<span class="ebx-filters-text__overlay">Priimti</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtruoti</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }, groups: {} },
    titles: { hierarchical_category: 'Kategorijos', eb_colors_facet: 'Spalva', size_facet: 'Dydis' },
    values: {}
  },
  history: { title: 'Pastarosios paieškos', enable: 'Aktyvuoti', disable: 'Išjungti', clear: 'Išvalyti' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Jus taip pat gali dominti' },
  nextQueries: {
    titleHTML: 'Jus taip pat gali dominti',
    nextQuery: {
      titleHTML: 'Kiti pirkėjai ieškojo: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Žiūrėti daugiau'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Deja, neradome nieko, kas atitiktų jūsų paiešką „</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">“. Štai ko labiausiai ieškota šiandien:</span>'
  },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Nerasta rezultatų pagal paiešką „</span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">“, tačiau jus galėtų dominti:</span>',
    viewAllHTML: 'Rodyti viską {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Matyti panašius rezultatus</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Populiarios paieškos', inCategory: 'in' },
  recommendations: { title: 'Galbūt jus domintų', showAll: 'Rodyti viską' },
  results: { addToCart: 'Pridėti', identifierTitle: '' },
  resultsSuggestions: { title: 'Ieškomiausi gaminiai' },
  resultsFoundHTML: 'Paieškos rezultatai. {resultsNumber} rezultatai (-ų)',
  search: { inputPlaceholderAlternativesHTML: ['Ieškoti gaminių'] },
  selectedFilters: { title: 'Pasirinkti filtrai ({count})' },
  sort: { titleHTML: 'Rūšiuoti', sortTitles: { default: 'Aktualumas', priceAsc: 'Kaina didėjanti', priceDesc: 'Kaina mažėja' } },
  spellcheck: 'Nepavyko rasti rezultatų pagal „{query}“. Rodome rezultatus pagal „{spellcheck}“.',
  suggestions: { title: 'Ieškomiausieji', inCategory: 'in' }
};
