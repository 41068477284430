/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const pl: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Filtry'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtruj</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtruj</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Usuń wszystko'
  },
  history: {
    clear: 'Usuń ostatnie'
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">NNiestety nie znaleźliśmy żadnych wyników pasujących do kryteriów Twojego wyszukiwania "</span> <span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again">, pokazujemy najczęściej wyszukiwane dziś:</span>',
    recommendations: {
      title: 'Może zainteresuje Cię'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Brak wyników dla </span><span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, być może zainteresuje Cię:</span>',
    viewAllHTML: 'Zobacz wszystko ({totalResults})',
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Wyniki wyszukiwania. </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span> wyniki',
  search: {
    clear: 'Usuń'
  },
  sort: {
    title: 'Uporządkuj'
  }
};
