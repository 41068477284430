






import { DynamicConfig, SUBMODULES_PATHS } from '@empathybroker/ebx-gluon';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  computed: {
    ...mapState(SUBMODULES_PATHS.config, ['dynamic'])
  }
})
export default class OyshoFakeHeader extends Vue {
  protected dynamic!: DynamicConfig;
  protected paddingEnd = 0;

  protected get style(): Partial<CSSStyleDeclaration> {
    const paddingPropertyName = this.dynamic.documentDirection === 'ltr' ? 'paddingRight' : 'paddingLeft';
    return { [paddingPropertyName]: `${ this.paddingEnd }px` };
  }

  mounted() {
    this.paddingEnd = 22 + (document.querySelector<HTMLElement>('.header__block--right')?.offsetWidth ?? 208);
  }
}
