/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const lv: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Skatīt' },
  custom: {
    mobileFacetsHeader: 'Filtri',
    headerTitle: 'Rezultāti',
    comingSoon: 'drīzumā',
    facetsTitle: 'Piemērot filtrus',
    closeFacets: 'Aizvērt',
    footerButtonOverlay: 'Izmantot',
    priceDisclaimer: '*Atlaide ir piemērota sezonas cenai'
  },
  facets: {
    clearFilters: 'Dzēst visu',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Rādīt mazāk',
    showMore: 'Rādīt vairāk filtru ({filtersNumber})',
    toggle: { default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Piemērot filtrus</span>' +
          '<span class="ebx-filters-text__overlay">Pieņemt</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Piemērot filtrus </span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }, groups: {} },
    titles: { hierarchical_category: 'Kategorijas', eb_colors_facet: 'Krāsa', size_facet: 'Izmērs' }
  },
  history: { title: 'Nesen veiktie meklējumi', enable: 'Aktivizēt', disable: 'Deaktivizēt', clear: 'Izdzēst' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Jums varētu patikt' },
  nextQueries: {
    titleHTML: 'Jums varētu interesēt',
    nextQuery: {
      titleHTML: 'Citi klienti ir meklējuši: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Skatīt vairāk'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Diemžēl neesam atraduši rezultātus, kas atbilstu jūsu meklējumam "</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">.", tāpēc rādām šodien vismeklētāko:</span>'
  },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Nerasta rezultatų pagal paiešką „</span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">“, tačiau jus galėtų dominti:</span>',
    viewAllHTML: 'Rodyti viską {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Skatīt līdzīgus rezultātus</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Populāri meklējumi', inCategory: 'in' },
  recommendations: { title: 'Varbūt jūs interesēs', showAll: 'Skatīt visus' },
  results: { addToCart: 'Pievienot', identifierTitle: '' },
  resultsSuggestions: { title: 'Vismeklētākās preces' },
  resultsFoundHTML: 'Meklēšanas rezultāti. {resultsNumber} rezultāti',
  search: { inputPlaceholderAlternativesHTML: ['Meklēt preces'] },
  selectedFilters: { title: 'Izvēlētie filtrs ({count})' },
  sort: { titleHTML: 'Kārtot', sortTitles: { default: 'Atbilstība', priceAsc: 'Cena pieaug', priceDesc: 'Cena samazinās' } },
  spellcheck: 'Neatradām rezultātus meklējumam \'{query}\'. Rādām rezultātus meklējumam \'{spellcheck}\'',
  suggestions: { title: 'Vismeklētākais', inCategory: 'in' }
};
