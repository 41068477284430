/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const ar: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'فرز'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">منقي</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">منقي</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'حذف الكل'
  },
  history: {
    clear: 'حذف'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">للأسف لم نعثر على أي شيء يطابق بحثك</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'قد يهمّك'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">لا توجد نتائج لـ  </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, ربما تكون مهتمًا بها: </span>`,
    viewAllHTML: 'نرى {totalResults} النتائج</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">نتائج البحث </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'حذف الأحدث'
  },
  sort: {
    title: 'فرز'
  }
};
