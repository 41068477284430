
import { EVENTS } from '@empathybroker/ebx-gluon';
import Add2CartOnProductPage from '@empathybroker/ebx-gluon/dist/components/pure/add2cart-on-product-page.vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class OyshoAdd2CartOnProductPage extends Add2CartOnProductPage {
  @Prop()
  private domAdd2CartSelector: string;

  onAdd2CartClicked() {
    if (!this.desktopAddToCart()) {
      this.mobileAddTocart();
    }
  }

  private desktopAddToCart(): boolean {
    const desktopSizesDom = document.querySelector('.product_sizes #sizes_list > li.active > span');
    const dataId = desktopSizesDom ? desktopSizesDom.getAttribute('data-id') : '';
    if (dataId) {
      this.$x.emit(EVENTS.Add2CartOnProductPageClicked);
    }
    return !!desktopSizesDom;
  }

  private mobileAddTocart() {
    this.$x.emit(EVENTS.Add2CartOnProductPageClicked);
  }

  eventListener(event: Event) {
    const domAdd2CartButton = this.obtainDomAdd2CartButton();
    const target = event.target as HTMLElement;
    if (domAdd2CartButton && (target === domAdd2CartButton || domAdd2CartButton.contains(target))) {
      this.onAdd2CartClicked();
    }
  }

  obtainDomAdd2CartButton(): HTMLElement {
    const selectors = this.domAdd2CartSelector.split(',');
    /*
     Oysho is using different buttons when you want to add2cart with or without scrolling.
     In order to detect which button is in use we need to search by '.fast-shop-bar--hidden'
     css selector.
     */
    return document.querySelector('.fast-shop-bar--hidden')
        ? document.querySelector(selectors[0]) as HTMLElement
        : document.querySelector(selectors[1]) as HTMLElement;
  }
}
