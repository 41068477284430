import { DeepPartial } from '@empathybroker/ebx-gluon';

export const mk: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Нарачај'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Филтрирај</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Филтрирај</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Избриши ги сите'
  },
  history: {
    clear: 'Избриши неодамнешни'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">За жал, не најдовме ништо што одговара на пребарувањето</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Можеби ве интересира'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Нема резултати за </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, може да ве интересира: </span>`,
    viewAllHTML: 'Види ги сите {totalResults}</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Резултати од пребарувањето </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Избриши',
    inputPlaceholderAlternativesHTML: [
      'Пребарај производи'
    ]
  },
  sort: {
    title: 'Нарачај'
  }
};
