/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const mk: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Види' },
  custom: {
    mobileFacetsHeader: 'Филтри',
    headerTitle: 'Резултати',
    comingSoon: 'доаѓа наскоро',
    facetsTitle: 'Филтрирај',
    closeFacets: 'Затвори',
    footerButtonOverlay: 'Примени',
    priceDisclaimer: '*Попустот се применува на сезонската цена',
    colors: {
      Yellow: 'ЖОЛТО',
      White: 'БЕЛО',
      Teal: 'ТИРКИЗНО',
      Red: 'ЦРВЕНО',
      Purple: 'ПУРПУРНО',
      Pink: 'РОЗЕВО',
      Orange: 'ПОРТОКАЛОВО',
      Olive: 'МАСЛИНЕСТО',
      Navy: 'ТЕГЕТ',
      Maroon: 'БОРДО',
      Magenta: 'ФУКСИЈА',
      Green: 'REENЗЕЛЕНО',
      Gray: 'СИВО',
      Brown: 'КАФЕАВО',
      Blue: 'СИНО',
      Black: 'ЦРНО',
      Beige: 'БЕЖ'
    }
  },
  facets: {
    clearFilters: 'Избриши ги сите',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Прикажи помалку',
    showMore: 'Прикажи повеќе филтри ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Филтрирај</span>' +
          '<span class="ebx-filters-text__overlay">Прифати</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Филтрирај</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }, groups: {}
    },
    titles: {
      hierarchical_category: 'Категории',
      eb_colors_facet: 'Боја'
    }
  },
  history: {
    title: 'Неодамнешни пребарувања',
    enable: 'Активирај',
    disable: 'Деактивирај',
    clear: 'Исчисти'
  },
  nextQueriesSuggestions: {
    title: 'Може да ве интересира'
  },
  nextQueries: {
    titleHTML: 'Може да те интересира',
    nextQuery: {
      titleHTML: 'Би можело да ве интересира <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Дознај повеќе ({totalResults})'
    }
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">За жал, не најдовме ништо што одговара на пребарувањето </span>
<span class="ebx-no-results__message-query"> \"{query}\"</span><span class="ebx-no-results__message-try-again">, ви ги прикажуваме денешните најпребарувани артикли:</span>`,
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Нема резултати за </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, може да ве интересира:</span>`,
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    viewAllHTML: 'Види {totalResults} резултати</span>'
  },
  promotedLabel: '',
  popularSearches: { title: 'Популарни пребарувања', inCategory: 'во' },
  recommendations: { title: 'Можеби ве интересира', showAll: 'Види ги сите' },
  results: {
    addToCart: 'Додај',
    identifierTitle: ''
  },
  resultsSuggestions: { title: 'Најпребарувани производи' },
  resultsFoundHTML: '<span class="ebx-results-number__title">Резултати од пребарувањето. </span>&nbsp;<span class="ebx-results-number__count">{resultsNumber} резултати</span>',
  search: {
    clear: 'Избриши',
    inputPlaceholderAlternativesHTML: [
      'Пребарај производи:\n'
    ]
  },
  selectedFilters: { title: 'Избрани филтри ({count})' },
  sort: {
    titleHTML: 'Нарачај',
    sortTitles: {
      default: 'Релевантност',
      priceAsc: 'Растечка цена',
      priceDesc: 'Опаѓачка цена'
    }
  },
  spellcheck: 'Не најдовме резултати за \'{query}\'. Ви ги прикажуваме резултатите за  \'{spellcheck}\'',
  suggestions: { title: 'Најпребарувано', inCategory: 'вo' }
};
