import { COMPONENTS, DeepPartial, PartialsConfig, ResultComponentsConfig } from '@empathybroker/ebx-gluon';

export const MOBILE_PARTIALS_CONFIG: DeepPartial<PartialsConfig> = {
  maxSearchResultsToShowPartials: 3,
  gridColumns: 2,
  components: {
    partial: {
      grid: COMPONENTS.PANELS.SlidingPanel,
      footer: null
    }
  }
};

export const DESKTOP_PARTIALS_CONFIG: DeepPartial<PartialsConfig> = {
  gridColumns: 4,
  components: {
    partial: {
      grid: COMPONENTS.PURE.DefaultGrid,
    }
  }
};

export const MOBILE_PARTIAL_RESULT_CONFIG: DeepPartial<ResultComponentsConfig> = {
  link: {
    children: {
      rating: null,
      price: null,
      title: null,
      addToCart: null,
      priceTitle: null
    }
  }
};

export const DESKTOP_PARTIAL_RESULT_CONFIG: DeepPartial<ResultComponentsConfig> = {
  link: {
    children: {
      image: {
        children: {
          brokenImage: {
            component: 'OyshoFallbackImage'
          }
        }
      },
      title: {
        component: COMPONENTS.PURE.ResultTitle
      },
      price: {
        component: 'OyshoResultsPrice'
      }
    }
  }
};
