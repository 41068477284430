/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const sv: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Se' },
  custom: {
    mobileFacetsHeader: 'Filter',
    headerTitle: 'Resultat',
    comingSoon: 'kommer snart',
    facetsTitle: 'Filtrera',
    closeFacets: 'Stäng',
    footerButtonOverlay: 'Använd',
    priceDisclaimer: '*Discount applied on season price',
    colors: {
      Yellow: 'GUL',
      White: 'VIT',
      Teal: 'TURKOS',
      Red: 'RÖD',
      Purple: 'PURPUR',
      Pink: 'ROSA',
      Orange: 'ORANGE',
      Olive: 'OLIVGRÖN',
      Navy: 'MARINBLÅ',
      Maroon: 'GRANAT',
      Magenta: 'CERISEROSA',
      Green: 'GRÖN',
      Gray: 'GRÅ',
      Brown: 'BRUN',
      Blue: 'BLÅ',
      Black: 'SVART',
      Beige: 'BEIGE'
    }
  },
  facets: {
    clearFilters: 'Ta bort allt',
    defaultRange: {
      lessHTML: '< {max}',
      betweenHTML: '{min} - {max}',
      fromHTML: '> {min}'
    },
    defaultDynamicRange: {
      clear: '×',
      filter: '→'
    },
    showLess: 'Visa mindre',
    showMore: 'Visa mer filter ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filtrera</span>' +
          '<span class="ebx-filters-text__overlay">Godkänn</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrera</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      },
      groups: {}
    },
    titles: {
      hierarchical_category: 'Kategorier',
      eb_colors_facet: 'Färg',
      size_facet: 'Storlek'
    },
    values: {}
  },
  history: {
    title: 'Senaste sökningar',
    enable: 'Aktivera',
    disable: 'Avaktivera',
    clear: 'Rensa'
  },
  identifierSuggestions: {
    title: ''
  },
  nextQueriesSuggestions: {
    title: 'Kan intressera dig'
  },
  nextQueries: {
    titleHTML: 'Kan intressera dig',
    nextQuery: {
      titleHTML: 'Andra kunder har sökt: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Se mer'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Tyvärr har vi inte hittat något som matchade din sökning </span><span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again">, vi kommer att visa de mest sökta idag:</span>'
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Det finns inga resultat för </span><span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, men kanske dessa kan intressera dig:</span>',
    viewAllHTML: 'Se alla ({totalResults})',
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Se liknande resultat</span>`
  },
  promotedLabel: '',
  popularSearches: {
    title: 'Populära sökningar',
    inCategory: 'i'
  },
  recommendations: {
    title: 'Du kanske är intresserad av',
    showAll: 'Se alla'
  },
  results: {
    addToCart: 'Lägg till',
    identifierTitle: ''
  },
  resultsSuggestions: {
    title: 'Mest sökta produkter'
  },
  resultsFoundHTML: 'Sökresultat. {resultsNumber} resultat',
  search: {
    clear: 'Radera',
    inputPlaceholderAlternativesHTML: ['Sök produkter']
  },
  selectedFilters: {
    title: 'Valda filter ({count})'
  },
  sort: {
    titleHTML: 'Sortera',
    sortTitles: {
      default: 'Relevans',
      priceAsc: 'Pris stigande',
      priceDesc: 'Pris fallande'
    }
  },
  spellcheck: 'Vi hittade inga resultat för \'{query}\'. Vi visar dig resultat för \'{spellcheck}\'',
  suggestions: {
    title: 'De mest sökta',
    inCategory: 'i'
  }
};
