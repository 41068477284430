/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const de: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Ansehen' },
  custom: {
    mobileFacetsHeader: 'Filter',
    headerTitle: 'Ergebnisse',
    comingSoon: 'coming soon',
    facetsTitle: 'Filtern',
    closeFacets: 'Schließen',
    footerButtonOverlay: 'Anwenden',
    priceDisclaimer: '* Rabatt auf den Saisonpreis',
    colors: {
      Yellow: 'GELB',
      White: 'WEISS',
      Teal: 'TÜRKIS',
      Red: 'ROT',
      Purple: 'PURPUR',
      Pink: 'ROSA',
      Orange: 'ORANGE',
      Olive: 'OLIVGRÜN',
      Navy: 'MARINE',
      Maroon: 'GRANATROT',
      Magenta: 'FUCHSIA',
      Green: 'GRÜN',
      Gray: 'GRAU',
      Brown: 'BRAUN',
      Blue: 'BLAU',
      Black: 'SCHWARZ',
      Beige: 'BEIGE'
    }
  },
  facets: {
    clearFilters: 'Alles löschen',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Weniger anzeigen',
    showMore: 'Mehr Filter anzeigen ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Kategorien', eb_colors_facet: 'Farbe', size_facet: 'Größe' },
    values: {}
  },
  history: {
    title: 'Kürzliche Suchanfragen',
    enable: 'Aktivieren',
    disable: 'Deaktivieren',
    clear: 'Löschen'
  },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Das könnte Ihnen gefallen ...' },
  nextQueries: {
    titleHTML: 'Das könnte Ihnen gefallen ... <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
    nextQuery: {
      titleHTML: 'Andere Kunden haben gesucht: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Mehr sehen'
    }
  },
  noResults: { messageHTML: '<span class="ebx-no-results__message-apologies">Leider konnten wir zu Ihrer Suche nach "</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">" nichts finden, wir zeigen Ihnen die heute meistgesuchten Artikel:</span>' },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Für </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again"> wurden keine Ergebnisse gefunden, aber das könnte Sie interessieren:</span>',
    viewAllHTML: 'Alle ansehen {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Ähnliche Ergebnisse ansehen</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Beliebte Suchanfragen', inCategory: 'in' },
  recommendations: { title: 'Vielleicht interessant für Sie', showAll: 'Alle ansehen' },
  results: { addToCart: 'Hinzufügen', identifierTitle: '' },
  resultsSuggestions: { title: 'Meistgesuchte Artikel' },
  resultsFoundHTML: 'Suchergebnisse. {resultsNumber} Ergebnisse',
  search: { inputPlaceholderAlternativesHTML: ['Artikel suchen'] },
  selectedFilters: { title: 'Ausgewählte Filter ({count})' },
  sort: { titleHTML: 'Sortieren', sortTitles: { default: 'Relevanz', priceAsc: 'Preis aufsteigend', priceDesc: 'Preis absteigend' } },
  spellcheck: 'Wir haben keine Ergebnisse für \'{query}\' gefunden. Wir zeigen Ihnen Ergebnisse für \'{spellcheck}\'.',
  suggestions: { title: 'Meistgesuchte Artikel', inCategory: 'in' }
};
