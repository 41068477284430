/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const tr: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Bak' },
  custom: {
    mobileFacetsHeader: 'Filtreler',
    headerTitle: 'Sonuçlar',
    comingSoon: 'çok yakında',
    facetsTitle: 'Filtrele',
    closeFacets: 'Kapat',
    footerButtonOverlay: 'Uygula',
    priceDisclaimer: '*Sezon fiyatı üzerinden uygulanan indirim',
    colors: {
      Yellow: 'Sarı',
      White: 'Beyaz',
      Teal: 'Turkuaz',
      Red: 'Kırmızı',
      Purple: 'Mor',
      Pink: 'Pembe',
      Orange: 'Turuncu',
      Olive: 'Zeytin yeşili',
      Navy: 'Lacivert',
      Maroon: 'Nar kırmızısı',
      Magenta: 'Fuşya',
      Green: 'Yeşil',
      Gray: 'Gri',
      Brown: 'Kahverengi',
      Blue: 'Mavi',
      Black: 'Siyah',
      Beige: 'Bej'
    }
  },
  facets: {
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    clearFilters: 'Hepsini sil',
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Daha az göster',
    showMore: 'Daha fazla filtre göster ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filtrele</span>' +
          '<span class="ebx-filters-text__overlay">Kabul et</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrele</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }, groups: {}
    },
    titles: { hierarchical_category: 'Kategoriler', eb_colors_facet: 'Renk', size_facet: 'Boyut' },
    values: {}
  },
  history: {
    title: 'En son aramalar',
    enable: 'Etkinleştir',
    disable: 'Devre dışı bırak',
    clear: 'En yenileri temizle'
  },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Hoşunuza gidebilir' },
  nextQueries: {
    titleHTML: 'Hoşunuza gidebilir',
    nextQuery: {
      titleHTML: 'Başka müşteriler aradı: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Daha fazla gör'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Maalesef </span><span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again"> aramanıza uyan hiçbir şey bulamadık, bugün en fazla arananları gösteriyoruz:</span>'
  },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again"> için sonuç yok, belki şu ilginizi çekebilir:</span>',
    viewAllHTML: 'Hepsini gör ({totalResults})',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Benzer sonuçları gör</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Popüler Aramalar', inCategory: 'içinde' },
  recommendations: { title: 'Belki ilginizi çeker', showAll: 'Hepsini gör' },
  results: { addToCart: 'Ekle', identifierTitle: '' },
  resultsSuggestions: { title: 'En fazla aranan ürünler' },
  resultsFoundHTML: 'Arama sonuçları. {resultsNumber} sonuç',
  search: { inputPlaceholderAlternativesHTML: ['Ürün ara'] },
  selectedFilters: { title: 'Seçilen filtreler ({count})' },
  sort: { titleHTML: 'Düzenle', sortTitles: { default: 'Ilgi', priceAsc: 'Artan fiyat', priceDesc: 'Azalan fiyat' } },
  spellcheck: '\'{query}\' için sonuç bulamadık. \'{spellcheck}\' için sonuçları gösteriyoruz',
  suggestions: { title: 'En fazla arananlar', inCategory: 'içinde' }
};
