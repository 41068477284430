/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const eu: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Ikusi' },
  custom: {
    mobileFacetsHeader: 'Iragazkiak',
    headerTitle: 'Emaitzak',
    comingSoon: 'aurki',
    facetsTitle: 'Iragazi',
    closeFacets: 'Itxi',
    footerButtonOverlay: 'Aplikatu',
    priceDisclaimer: '*Denboraldiko prezioan aplikatutako deskontua',
    colors: {
      Yellow: 'HORIA',
      White: 'ZURIA',
      Teal: 'TURKESA',
      Red: 'GORRIA',
      Purple: 'PURPURA',
      Pink: 'ARROSA',
      Orange: 'LARANJA',
      Olive: 'OLIBA',
      Navy: 'URDIN ILUNA',
      Maroon: 'GORRILUNA',
      Magenta: 'FUKSIA',
      Green: 'BERDEA',
      Gray: 'GRISA',
      Brown: 'MARROIA',
      Blue: 'URDINA',
      Black: 'BELTZA',
      Beige: 'BEIXA'
    }
  },
  facets: {
    clearFilters: 'Ezabatu guztia',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Erakutsi gutxiago',
    showMore: 'Erakutsi iragazki gehiago ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Kategoriak', eb_colors_facet: 'Kolorea', size_facet: 'Tamaina' },
    values: {}
  },
  history: { title: 'Duela gutxiko bilaketak', enable: 'Aktibatu', disable: 'Desaktibatu', clear: 'Garbitu' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Interesgarria izan daiteke zuretzat' },
  nextQueries: {
    titleHTML: 'Interesgarria izan liteke zuretzat',
    nextQuery: {
      titleHTML: 'Hauxe bilatu dute beste bezero batzuek: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Ikus gehiago'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Tamalez, ez dugu "</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">" bilaketarekin bat datorren emaitzarik aurkitu. Gaur gehien bilatu dena erakusten dizugu:</span>'
  },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Ez dago emaitzarik ":</span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">" bilaketarako. Baliteke hau interesatzea:</span>',
    viewAllHTML: 'Ikusi denak {numFound}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Antzeko emaitzak ikusi</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Bilaketa popularrak', inCategory: 'in' },
  recommendations: { title: 'Agian interesatzen zaizu', showAll: 'Ikusi denak' },
  results: { addToCart: 'Gehitu', identifierTitle: '' },
  resultsSuggestions: { title: 'Produktu bilatuenak' },
  resultsFoundHTML: 'Bilaketaren emaitzak. {resultsNumber} emaitza',
  search: { inputPlaceholderAlternativesHTML: ['Bilatu produktuak'] },
  selectedFilters: { title: 'Hautatutako iragazkiak ({count})' },
  sort: { titleHTML: 'Ordenatu', sortTitles: { default: 'Garrantzia', priceAsc: 'Prezioa igoera', priceDesc: 'Prezioa jaistea' } },
  spellcheck: 'Ez da aurkitu emaitzarik \'{query}\' bilaketarentzat. \'{spellcheck}\' bilaketarentzako emaitzak erakusten dizkizugu',
  suggestions: { title: 'Bilatueta', inCategory: 'in' }
};
