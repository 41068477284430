








import { OyshoResult } from '@/adapter/oysho.models';
import OyshoColor from '@/components/add-to-cart/oysho-color.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    OyshoColor
  }
})
export default class OyshoResultColors extends Vue {
  @Prop({ required: true })
  item!: OyshoResult;
}
