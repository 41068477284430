/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const et: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Nägema' },
  custom: {
    mobileFacetsHeader: 'Filtrid',
    headerTitle: 'Tulemused',
    comingSoon: 'peagi tulekul',
    facetsTitle: 'Filtreerima',
    closeFacets: 'Sulgema',
    footerButtonOverlay: 'Rakendama',
    priceDisclaimer: '*Soodustus kehtib hooajahinnale'
  },
  facets: {
    clearFilters: 'Kustuta kõik',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Kuva vähem',
    showMore: 'Kuva rohkem filtreid ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Kategooriad', eb_colors_facet: 'Värv', size_facet: 'Suurus' },
    values: {}
  },
  history: { title: 'Hiljutised otsingud', enable: 'Aktiveerima', disable: 'Deaktiveerima', clear: 'Puhasta' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Sulle võib huvi pakkuda' },
  nextQueries: {
    titleHTML: 'Sulle võiks huvi pakkuda',
    nextQuery: {
      titleHTML: 'Teised kliendid on otsinud: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Vaata lisaks'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies"> Kahjuks pole me leidnud midagi, mis vastaks su otsingule "</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">", näitame sulle kõige otsitumaid täna:</span>'
  },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Päringule </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again"> pole tulemusi, võib-olla huvitab sind:</span>',
    viewAllHTML: 'Vaata kõike {totalResults} </span>',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Vaata sarnaseid tulemusi</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Populaarsed otsingud', inCategory: 'sisse' },
  recommendations: { title: 'Võib-olla olete huvitatud', showAll: 'Vaata kõike' },
  results: { addToCart: 'Lisa', identifierTitle: '' },
  resultsSuggestions: { title: 'Enim otsitud tooted' },
  resultsFoundHTML: 'Otsingutulemused. {resultsNumber} tulemused',
  search: { inputPlaceholderAlternativesHTML: ['Otsi tooteid'] },
  selectedFilters: { title: 'Valitud filtrid ({count})' },
  sort: { titleHTML: 'Tellima', sortTitles: { default: 'Asjakohasus', priceAsc: 'Hind tõuseb', priceDesc: 'Hind langeb' } },
  spellcheck: 'Päringule „{query}” ei leitud tulemusi. Näitame teile tulemusi otsingule „{spellcheck}”',
  suggestions: { title: 'Enim otsitud', inCategory: 'sisse' }
};
