/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const uk: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Переглянути' },
  custom: {
    mobileFacetsHeader: 'Фільтри',
    headerTitle: 'Результати',
    comingSoon: 'незабаром у наявності',
    facetsTitle: 'Фільтрувати',
    closeFacets: 'Закрити',
    footerButtonOverlay: 'Застосувати',
    priceDisclaimer: '*Знижку застосовано до сезонної ціни',
    colors: {
      Yellow: 'Жовтий',
      White: 'Білий',
      Teal: 'Бірюза',
      Red: 'Червоний',
      Purple: 'Фіолетовий',
      Pink: 'троянда',
      Orange: 'Помаранчевий',
      Olive: 'Оливкова',
      Navy: 'Морська',
      Maroon: 'Гранат',
      Magenta: 'Фуксія',
      Green: 'Зелений',
      Gray: 'Сірий',
      Brown: 'Коричневий',
      Blue: 'синій',
      Black: 'Чорний',
      Beige: 'Бежевий'
    }
  },
  facets: {
    clearFilters: 'Стерти все',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Показувати менше',
    showMore: 'Показувати більше фільтрів ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Фільтрувати</span>' +
          '<span class="ebx-filters-text__overlay">Прийняти</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Фільтрувати</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }, groups: {}
    },
    titles: { hierarchical_category: 'Категорії', eb_colors_facet: 'Колір', size_facet: 'розмір' },
    values: {}
  },
  history: {
    title: 'Недавні пошукові запити',
    enable: 'Активувати',
    disable: 'Деактивувати',
    clear: 'Очистити'
  },
  identifierSuggestions: {
    title: ''
  },
  nextQueriesSuggestions: {
    title: 'Вас може зацікавити'
  },
  nextQueries: {
    titleHTML: 'Вас може зацікавити',
    nextQuery: {
      titleHTML: 'Вас може зацікавити: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Показати більше'
    }
  },
  noResults: { messageHTML: '<span class="ebx-no-results__message-apologies">Шкода, проте ми не знайшли нічого, що відповідає вашому пошуковому запиту</span> «<span class="ebx-no-results__message-query">{query}</span>»<span class="ebx-no-results__message-try-again">. Перегляньте найпопулярніше за сьогодні:</span>' },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Немає результатів за запитом</span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, можливо, вас зацікавить:</span>',
    viewAllHTML: 'Переглянути все <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Переглянути схожі результати</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Популярні пошукові запити', inCategory: 'в' },
  recommendations: { title: 'Можливо, вам буде цікаво', showAll: 'Переглянути всі' },
  results: { addToCart: 'Додати', identifierTitle: '' },
  resultsSuggestions: { title: 'Найпопулярніші товари' },
  resultsFoundHTML: 'Результати пошукового запиту. {resultsNumber} результати(-ів)',
  search: { inputPlaceholderAlternativesHTML: ['Шукати товари'] },
  selectedFilters: { title: 'Обрані фільтри ({count})' },
  sort: {
    titleHTML: 'Впорядкувати',
    sortTitles: { default: 'Релевантність', priceAsc: 'Від найдешевшого', priceDesc: 'Від найдорожчого ' }
  },
  spellcheck: 'Не вдалося знайти результатів для \'{query}\'. Покажемо вам результати \'{spellcheck}\'',
  suggestions: { title: 'Найпопулярніше', inCategory: 'в' }
};
