import { mapOyshoFilter } from '@/adapter/oysho-filter.mapper';
import { mapOyshoResult } from '@/adapter/oysho-result.mapper';
import { ConfigureInditexOptions } from '@empathy/inditex-utils';
import { DEPENDENCIES } from '@empathy/search-adapter';
import { BeaconTrackingRequestor } from '@empathyco/x-adapter';

export const OYSHO_CONFIG: ConfigureInditexOptions = {
  configureAdapter(builder) {
    builder
      .addMapper(mapOyshoFilter, 'filters')
      .addMapper(mapOyshoResult, 'results')
      .setFacetConfig({
        prefix: {
          facetName: ({ config: { requestParams: { store, catalogue } } }) => `hierarchical_category_${ catalogue }_${ store }`,
          noTagFacetName: 'hierarchical_category_facet'
        }
      }, 'hierarchical_category')
      .setResultsURLConfig({
        hostname: 'www.oysho.com',
        addColorId: {
          search: false,
          searchById: true,
          topRecommendations: false
        }
      })
      .onBeforeRequest(({ request }) => {
        delete request.user;
        delete request.user_type;
      })
      .configureContainer(container => {
        container.rebind(DEPENDENCIES.Requestors.track).to(BeaconTrackingRequestor);
      });
  }
};
