/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const bg: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Вижте' },
  custom: {
    mobileFacetsHeader: 'Филтри',
    headerTitle: 'Резултати',
    comingSoon: 'очаквайте скоро',
    facetsTitle: 'Филтриране',
    closeFacets: 'Затваряне',
    footerButtonOverlay: 'Прилагане',
    priceDisclaimer: '*Отстъпката е приложена върху сезонната цена'
  },
  facets: {
    clearFilters: 'Изтриване на всичко',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Показване на по-малко',
    showMore: 'Показване на повече филтри ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Категории', eb_colors_facet: 'цвят', size_facet: 'размер' },
    values: {}
  },
  history: {
    title: 'Скорошни търсения',
    enable: 'Активиране',
    disable: 'Деактивиране',
    clear: 'Изчистване'
  },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Може да ви заинтересува също' },
  nextQueries: {
    titleHTML: 'Може да ви заинтересува също',
    nextQuery: {
      titleHTML: 'Други клиенти са търсили: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Вижте повече'
    }
  },
  noResults: { messageHTML: '<span class="ebx-no-results__message-apologies">За съжаление не открихме нищо„ което да отговаря на вашето търсене, </span><span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">, сега ще ви покажем най-търсените неща:</span>' },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Няма резултати за „</span><span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, може би това ще ви заинтересува:</span>',
    viewAllHTML: 'Вижте всички {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Вижте подобни резултати</span>`
  },
  promotedLabel: '',
  popularSearches: { title: 'Популярни търсения', inCategory: 'в' },
  recommendations: { title: 'Може би ще ви заинтригува', showAll: 'Вижте всички' },
  results: { addToCart: 'Добавяне', identifierTitle: '' },
  resultsSuggestions: { title: '' },
  resultsFoundHTML: 'Резултати от търсенето. {resultsNumber} резултата',
  search: { inputPlaceholderAlternativesHTML: ['Търсене на продукти'] },
  selectedFilters: { title: 'Избрани филтри ({count})' },
  spellcheck: 'Не намерихме резултати за „{query}“. Показваме резултати за „{spellcheck}“',
  sort: { titleHTML: 'Поръчване', sortTitles: { default: 'уместност', priceAsc: 'Цена асцедент', priceDesc: 'Цена потомък' } },
  suggestions: { title: 'Най-търсеното', inCategory: 'в' }
};
