import { DeepPartial, HistoryConfig } from '@empathybroker/ebx-gluon';

export const HISTORY_CONFIG: DeepPartial<HistoryConfig> = {
  queryHistorySize: 4,
  components: {
    toggle: null,
    clearAll: {
      icon: null
    }
  }
};
