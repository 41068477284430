import { getSafePropertyChain } from '@empathy/get-safe-property-chain';
import { ProductPageService } from '@empathybroker/ebx-gluon';
import { injectable } from 'inversify';

@injectable()
export class OyshoProductPageService implements ProductPageService {
  getResultId(): string {
    return getSafePropertyChain(window, 'inditex.iProductId', '').toString();
  }
}
