/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const es: DeepPartial<Messages> = {
  custom: {
    mobileFacetsHeader: 'Filtros',
    facetsTitle: 'Filtrar',
    closeFacets: 'Cerrar',
    footerButtonOverlay: 'Aplicar',
    priceDisclaimer: '* Descuento aplicado sobre el precio de temporada',
    colors: {
      Yellow: 'AMARILLO',
      White: 'BLANCO',
      Teal: 'TURQUESA',
      Red: 'ROJO',
      Purple: 'PURPURA',
      Pink: 'ROSA',
      Orange: 'NARANJA',
      Olive: 'OLIVA',
      Navy: 'MARINO',
      Maroon: 'GRANATE',
      Magenta: 'FUCSIA',
      Green: 'VERDE',
      Gray: 'GRIS',
      Brown: 'MARRON',
      Blue: 'AZUL',
      Black: 'NEGRO',
      Beige: 'BEIGE'
    }
  },
  facets: {
    showMore: 'Mostrar mas filtros ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filtrar</span>' +
          '<span class="ebx-filters-text__overlay">Aceptar</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrar</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: '<span class="ebx-clear-filters__footer">Borrar</span><span class="ebx-clear-filters__overlay">Borrar todo</span>',
    titles: {
      eb_colors_facet: 'Color'
    }
  },
  history: {
    clear: 'Limpiar'
  },
  nextQueries: {
    titleHTML: 'Te podría interesar',
    nextQuery: {
      viewAllHTML: 'Ver más'
    }
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Lamentablemente no hemos encontrado nada que encaje con tu búsqueda</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span><span class="ebx-no-results__message-try-again">, te mostramos lo más buscado hoy:</span>`,
    recommendations: {
      title: ''
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">No hay resultados para </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, tal vez puedan interesarte:</span>`,
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    viewAllHTML: 'Ver {totalResults} resultados',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Ver resultados similares</span>`
  },
  results: {
    addToCart: 'Añadir'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Resultados búsqueda.</span>&nbsp;<span class="ebx-results-number__count">{resultsNumber} resultados</span>',
  search: {
    clear: 'Borrar',
    inputPlaceholderAlternativesHTML: [
      'Déjanos que te mostremos lo más buscado de hoy:\n'
    ]
  },
  sort: {
    titleHTML: 'ordenar',
    sortTitles: {
      default: 'Relevancia',
      priceAsc: 'Precio ascendente',
      priceDesc: 'Precio descendente'
    }
  },
  spellcheck: 'No hemos encontrado resultados para \"{query}\". Te mostramos resultados para \"{spellcheck}\":'
};
