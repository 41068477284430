/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const lt: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Filtrai'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtruoti</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtruoti</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Ištrinti viską'
  },
  history: {
    clear: 'Ištrinti paskutinius'
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Deja, neradome nieko, kas atitiktų jūsų paiešką „</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">“. Štai ko labiausiai ieškota šiandien:</span>',
    recommendations: {
      title: 'Galbūt jus domintų'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Nerasta rezultatų pagal paiešką „</span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">“, tačiau jus galėtų dominti:</span>',
    viewAllHTML: 'Rodyti viską {totalResults}'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Paieškos rezultatai. </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span> rezultatai (-ų)',
  search: {
    clear: 'Ištrinti'
  },
  sort: {
    title: 'Rūšiuoti'
  }
};
