/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const zh: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: '查看' },
  custom: {
    mobileFacetsHeader: '筛选条件',
    headerTitle: '结果',
    comingSoon: '即将推出',
    facetsTitle: '筛选',
    closeFacets: '关闭',
    footerButtonOverlay: '应用',
    priceDisclaimer: '*折扣适用于季节性价格',
    colors: {
      Yellow: '黄色',
      White: '白色',
      Teal: '1-014',
      Red: '红',
      Purple: '0-603',
      Pink: '玫瑰色',
      Orange: '橙黄色',
      Olive: '卡其',
      Navy: '深蓝色',
      Maroon: '石榴红',
      Magenta: '0-656',
      Green: '绿色',
      Gray: '灰色',
      Brown: '棕色',
      Blue: '蓝色',
      Black: '黑色',
      Beige: '米色'
    }
  },
  facets: {
    clearFilters: '删除全部',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: '隐藏',
    showMore: '显示更多筛选条件({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">筛选</span>' +
          '<span class="ebx-filters-text__overlay">接受</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">筛选</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      },
      groups: {}
    },
    titles: { hierarchical_category: '分类', eb_colors_facet: '颜色', size_facet: '尺码' },
    values: {}
  },
  history: { title: '近期搜索', enable: '激活', disable: '禁用', clear: '清理' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: '您可能会感兴趣' },
  nextQueries: {
    titleHTML: '您可能会感兴趣',
    nextQuery: {
      titleHTML: '其他顾客搜索了: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: '查看更多'
    }
  },
  noResults: { messageHTML: '<span class="ebx-no-results__message-apologies">抱歉，我们没有找到符合您的搜索条件"</span> <span class="ebx-no-results__message-query">{query}</span>"<span class="ebx-no-results__message-try-again">的商品， 我们为您显示今天的热门款式：</span>' },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">我们很抱歉！ 我们无法找到任何结果</span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, 但是我们发现了这些结果:</span>',
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    viewAllHTML: '查看所有结果 <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">查看相似结果</span>`
  },
  promotedLabel: '',
  popularSearches: { title: '热门搜索', inCategory: '在' },
  recommendations: { title: '猜您喜欢', showAll: '显示所有结果' },
  results: { addToCart: '添加', identifierTitle: '' },
  resultsSuggestions: { title: '热门产品' },
  resultsFoundHTML: '搜索结果。{resultsNumber}个结果',
  search: { inputPlaceholderAlternativesHTML: ['搜索商品'] },
  selectedFilters: { title: '选定的筛选条件({count})' },
  sort: { titleHTML: '排序', sortTitles: { default: '關聯', priceAsc: '價格上漲', priceDesc: '價格下降' } },
  spellcheck: '没有找到符合\'{query}\'的结果。我们为您显示符合\'{spellcheck}\'的结果',
  suggestions: { title: '热门款式', inCategory: '在' }
};
