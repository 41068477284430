/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const eu: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Ordenatu'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Iragazi</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Iragazi</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Ezabatu guztiak'
  },
  history: {
    clear: 'Ezabatu orain gutxikoak'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Zoritxarrez ez dugu zure bilaketarekin bat datorren ezer aurkitu</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Agian interesatzen zaizu'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Ez dago emaitzarik </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, agian interesatuko zaizu: </span>`,
    viewAllHTML: 'Ikusi {totalResults} emaitza</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Bilaketaren emaitzak </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Ezabatu azkenak'
  },
  sort: {
    title: 'Ordenatu'
  }
};
