




import { StorageService } from '@empathy/storage-service';
import {
  ClientEventCallbacks,
  ClientEventsDispatcher,
  container,
  CoreComponent,
  DEPENDENCIES,
  EVENTS,
  STORAGE
} from '@empathybroker/ebx-gluon';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    EmpathyX: CoreComponent
  }
})
export default class App extends Vue {
  mounted() {
    // Stop events propagation
    const eventsToStop = ['click', 'mousewheel', 'wheel', 'scroll', 'keyup', 'keydown'];
    eventsToStop.forEach(event => this.$el.addEventListener(event, e => {
      e.stopPropagation();
      this.forwardResultClicks(e);
    }));

    // Detect proper document direction and set it to the <html> so autoprefixer styles can work properly
    const documentDirection = document.documentElement.dir || 'ltr';
    document.documentElement.dir = documentDirection;
    this.$x.emit(EVENTS.DynamicConfigChanged, { documentDirection });

    // TODO Leave this here for a while to ensure that the previous user information is removed. Probably summer 2022 is enough.
    this.removeOldUserInfo();
  }

  private forwardResultClicks(event: Event): void {
    if (event.type === 'click' && (event.target as Element).getAttribute('class')?.indexOf('ebx-result') > -1) {
      const clientEventsDispatcher = container.get<ClientEventsDispatcher>(DEPENDENCIES.ClientEventsDispatcher);
      clientEventsDispatcher.dispatch({
        eventName: 'resultLinkClicked' as any,
        data: event
      });
    }
  }

  private removeOldUserInfo(): void {
    const storage = container.getNamed<StorageService>(DEPENDENCIES.StorageService, STORAGE.Local);
    storage.removeItem('user-id');
    storage.removeItem('user-type');
  }
}
