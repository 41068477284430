/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const da: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Sortere'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filter</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Slet alt'
  },
  history: {
    clear: 'slette de seneste'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Desværre har vi ikke fundet noget, der matcher din søgning</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Måske synes du om'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Der er ingen resultater for </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, måske er du måske interesseret i: </span>`,
    viewAllHTML: 'Se {totalResults} resultater</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Søgeresultater </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Slet seneste'
  },
  sort: {
    title: 'Sortere'
  }
};
