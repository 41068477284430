import { CloseConfig, DeepPartial } from '@empathybroker/ebx-gluon';

export const MOBILE_CLOSE_CONFIG: DeepPartial<CloseConfig> = {
  components: {
    icon: 'OyshoCaretDownIcon'
  }
};

export const DESKTOP_CLOSE_CONFIG: DeepPartial<CloseConfig> = {
  components: {
    close: null,
    icon: null
  }
};
