/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const hr: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Vrsta'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">filtar</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">filtar</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Izbrisati sve'
  },
  history: {
    clear: 'obriši nedavne'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Nažalost, nismo pronašli ništa što se podudara s vašim pretraživanjem</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Možda će vas zanimati'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Nema rezultata za </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, možda bi vas mogao zanimati: </span>`,
    viewAllHTML: 'Pogledajte {totalResults} rezultata</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Rezultati pretraživanja </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Izbriši nedavno'
  },
  sort: {
    title: 'Vrsta'
  }
};
