/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const fr: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Trier'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtre</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtre</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Tout supprimer'
  },
  history: {
    clear: 'Effacer les recherches récentes'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Malheureusement, nous n'avons rien trouvé qui corresponde à votre recherche</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Vous pourriez aussi aimer'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Il n'y a aucun résultat pour </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, peut-être que vous pourriez être intéressé par: </span>`,
    viewAllHTML: 'Voir {totalResults} résultats</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Résultats de recherche </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Supprimer récent'
  },
  sort: {
    title: 'Classer'
  }
};
