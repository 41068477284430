



















import { RootStoreState } from '@empathybroker/ebx-gluon';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { PreWarming } from '../adapter/oysho.models';
import PriceMixin from '../components/price.mixin';

@Component({
  computed: {
    ...mapState({
      isPreWarming: (state: Partial<RootStoreState>) =>
        state.config.dynamic.prewarming
    })
  }
})
export default class OyshoResultPreWarming extends mixins(PriceMixin) {

  protected isPreWarming: boolean;

  protected get preWarming(): PreWarming {
    return this.item.preWarming;
  }

  protected get showPreWarming(): boolean {
    return this.isPreWarming && !!(this.preWarming && Object.keys(this.preWarming).length);
  }

  protected get cssStyles(): Partial<CSSStyleDeclaration> {
    return {
      backgroundColor: this.preWarming.color
    };
  }
}
