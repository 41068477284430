import { NextQuery, Suggestion } from '@empathy/search-types';
import {
  EMPATHIZE_GETTERS_KEYS,
  EmpathizeState,
  EmpathyXConfig, GETTERS_KEYS,
  RootStoreState,
  SUBMODULES_PATHS
} from '@empathybroker/ebx-gluon';
import { StoreOptions } from 'vuex';

export const CUSTOM_GETTERS = {
  popularSearchesIfNextQueries: 'showPopularSearchesIfNextQueries',
  historyIfNextQueries: 'showHistoryIfNextQueries'
};

function returnIfNextQueries(getter: string) {
  return (localState: EmpathizeState, getters: any, state: RootStoreState, rootGetters: any): Suggestion[] => {
    const nextQueriesSuggestions: Suggestion[] = getters[EMPATHIZE_GETTERS_KEYS.nextQueriesSuggestions];
    return !nextQueriesSuggestions.length ? rootGetters[getter] : [];
  };
}

export const STORE_CONFIG: StoreOptions<RootStoreState> = {
  modules: {
    [SUBMODULES_PATHS.empathize]: {
      getters: {
        [EMPATHIZE_GETTERS_KEYS.nextQueriesSuggestions]:
          (localState: EmpathizeState, getters: any, rootState: RootStoreState, rootGetters: any): NextQuery[] => {
            const { nextQueriesSuggestions: config }: EmpathyXConfig = rootGetters[GETTERS_KEYS.config.config];
            const sessionQueryHistory: string[] = rootState.history.sessionQueryHistory;

            const nextQueriesSuggestions = config.filterBySessionQueryHistory
              ? rootState.querySignals.nextQueries.filter(nextQuery =>
                !sessionQueryHistory.includes(nextQuery.query))
              : rootState.querySignals.nextQueries;

            return nextQueriesSuggestions.slice(0, config.size);
          },
        [CUSTOM_GETTERS.popularSearchesIfNextQueries]: returnIfNextQueries(GETTERS_KEYS.empathize.popularSearches),
        [CUSTOM_GETTERS.historyIfNextQueries]: returnIfNextQueries(GETTERS_KEYS.history.queryHistory)
      }
    }
  }
};
