

























import {
  OyshoResult,
  OyshoResultColor,
  OyshoSize as OyshoSizeModel
} from '@/adapter/oysho.models';
import OyshoSize from '@/components/add-to-cart/oysho-size.vue';
import { EVENTS, RootStoreState } from '@empathybroker/ebx-gluon';
import { UpdateResultMutationPayload } from '@empathybroker/ebx-gluon/types/store/search/mutations/update-result.mutation';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  components: { OyshoSize },
  computed: {
    ...mapState({
      isEcommerce: (state: Partial<RootStoreState>) =>
        state.config.dynamic.isEcommerce ?? true
    })
  }
})
export default class OyshoAddToCart extends Vue {
  @Prop({ required: true })
  item!: OyshoResult;

  get sizes(): OyshoSizeModel[] {
    return this.variant?.sizes ?? [];
  }

  /**
   * Boolean flag to indicate if the current shop has ecommerce
   * and because of that the add2cart button should be displayed.
   *
   * */
  protected isEcommerce: boolean;

  get variant(): OyshoResultColor | null {
    return this.item.colors.find(
      variant => variant.color.id === this.item.selectedColor
    );
  }

  addToCart(size: OyshoSizeModel, event: MouseEvent) {
    /* This is done because when you click on an element, two events have to happen to emit a click, mousedown and mouseup,
     * but how the empathize closed, the mousedown and the mouseup events do not be executed in the same element (because
     * the grid and, it is elements are moved).
     * To avoid that, we prevent the mousedown in the element, to not lose the focus in the empathize, this will cause
     * that the mousedown and the mouseup will be in the same element (click event will be emitted) and after execute the
     * click, we will gain again the focus in the element to close the empathize.
    */
    (event.target as HTMLElement).focus();
    const [sizeSKU, sizeName, sizePartNumber] = size.id.split('|');
    const update: Partial<OyshoResult> = {
      callbackInfo: {
        product: {
          url: this.item.url,
          name: this.item.name,
          productId: this.item.productId,
          reference: this.item.identifier.value,
          sizeSKU,
          sizeName,
          sizePartNumber,
        },
        isAddToCart: true
      }
    };
    this.$x.emit<UpdateResultMutationPayload>(EVENTS.UpdateResult, {
      result: this.item as any,
      update
    });
    this.$x.emit(EVENTS.Add2CartOnResultClicked, this.item);
  }
}
