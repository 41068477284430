/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const pt: DeepPartial<Messages> = {
  close: {
    buttonText: ''
  },
  columnsPicker: {
    title: 'Ver'
  },
  custom: {
    mobileFacetsHeader: 'Filtros',
    headerTitle: 'Resultados',
    comingSoon: 'coming soon',
    facetsTitle: 'Filtrar',
    closeFacets: 'Fechar',
    footerButtonOverlay: 'Aplicar',
    priceDisclaimer: '*Desconto aplicado sobre o preço de temporada',
    colors: {
      Yellow: 'AMARELO',
      White: 'BRANCO',
      Teal: 'TURQUESA',
      Red: 'VERMELHO',
      Purple: 'PÚRPURA',
      Pink: 'ROSA',
      Orange: 'LARANJA',
      Olive: 'AZEITONA',
      Navy: 'MARINHO',
      Maroon: 'GRENÁ',
      Magenta: 'ROSA FORTE',
      Green: 'VERDE',
      Gray: 'CINZA',
      Brown: 'CASTANHO',
      Blue: 'AZUL',
      Black: 'PRETO',
      Beige: 'BEGE'
    }
  },
  facets: {
    clearFilters: 'Apagar tudo',
    defaultRange: {
      lessHTML: '< {max}',
      betweenHTML: '{min} - {max}',
      fromHTML: '> {min}'
    },
    defaultDynamicRange: {
      clear: '×',
      filter: '→'
    },
    showLess: 'Mostrar menos',
    showMore: 'Mostrar mais filtros ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filtrar</span>' +
          '<span class="ebx-filters-text__overlay">Aceitar</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrar</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      },
      groups: {}
    },
    titles: {
      hierarchical_category: 'Categorias',
      eb_colors_facet: 'Cor',
      size_facet: 'Tamanho'
    },
    values: {}
  },
  history: {
    title: 'Pesquisas recentes',
    enable: 'Ativar',
    disable: 'Desativar',
    clear: 'Limpar'
  },
  identifierSuggestions: {
    title: ''
  },
  nextQueriesSuggestions: {
    title: 'Poderá estar interessada em…'
  },
  nextQueries: {
    titleHTML: 'Poderia estar interessada em…',
    nextQuery: {
      titleHTML: 'Outros clientes pesquisaram: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Ver mais'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Infelizmente, não conseguimos encontrar algo que corresponda à sua pesquisa </span><span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again">. Mostramos-lhe o mais pesquisado hoje:</span>'
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Não foram encontrados resultados para </span><span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">. Poderá estar interessada em:</span>',
    viewAllHTML: 'Ver todos <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Ver resultados semelhantes</span>`
  },
  promotedLabel: '',
  popularSearches: {
    title: 'Pesquisas populares',
    inCategory: 'en'
  },
  recommendations: {
    title: 'Talvez esteja interessada em',
    showAll: 'Ver tudo'
  },
  results: {
    addToCart: 'Adicionar',
    identifierTitle: ''
  },
  resultsSuggestions: {
    title: 'Produtos mais pesquisados'
  },
  resultsFoundHTML: 'Resultados da pesquisa. {resultsNumber} resultados',
  search: {
    inputPlaceholderAlternativesHTML: ['Pesquisar produtos']
  },
  selectedFilters: {
    title: 'Filtros selecionados ({count})'
  },
  sort: {
    titleHTML: 'Ordenar',
    sortTitles: {
      default: 'Relevância',
      priceAsc: 'Preço ascendente',
      priceDesc: 'Preço decrescente'
    }
  },
  spellcheck: 'Não encontrámos resultados para \'{query}\'. Mostramos-lhe resultados para \'{spellcheck}\'',
  suggestions: {
    title: 'O mais pesquisado',
    inCategory: 'en'
  }
};
