/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const pt: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Filtros'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtrar</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrar</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Apagar tudo'
  },
  history: {
    clear: 'Apagar recentes'
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Infelizmente, não conseguimos encontrar algo que corresponda à sua pesquisa </span><span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again">. Mostramos-lhe o mais pesquisado hoje:</span>',
    recommendations: {
      title: 'Talvez esteja interessada em'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Não foram encontrados resultados para </span><span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">. Poderá estar interessada em:</span>',
    viewAllHTML: 'Ver todos <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Resultados da pesquisa. </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span> resultados',
  search: {
    clear: 'Apagar'
  },
  sort: {
    title: 'Ordenar'
  }
};
