/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const nl: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Filters'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filter</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Alles wissen'
  },
  history: {
    clear: 'Recente zoekopdrachten verwijderen'
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Helaas hebben we niets gevonden dat overeenkomt met je zoekopdracht </span> <span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again">. We laten je de meest gezochte artikelen van vandaag zien:</span>',
    recommendations: {
      title: 'Misschien vind je dit ook leuk'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Er zijn geen resultaten voor </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">. Misschien vind je dit interessant:</span>',
    viewAllHTML: 'Alles bekijken <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Zoekresultaten. </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span> resultaten',
  search: {
    clear: 'Wissen'
  },
  sort: {
    title: 'Sorteren'
  }
};
