/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const et: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Sorteeri'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtreeri</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtreeri</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Kustutada kõik'
  },
  history: {
    clear: 'Kustuta hiljutised'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Kahjuks pole me teie otsingule vastavat leidnud</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span>.`,
    recommendations: {
      title: 'Võib-olla olete huvitatud'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">Päringule pole tulemusi </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, võib-olla võiksite teid huvitada: </span>`,
    viewAllHTML: 'Vaadake {totalResults} tulemust</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Otsingu tulemused </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span>',
  search: {
    clear: 'Kustuta hiljutised'
  },
  sort: {
    title: 'Sorteeri'
  }
};
