/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const it: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Vedi' },
  custom: {
    mobileFacetsHeader: 'Filtri',
    headerTitle: 'Risultati',
    comingSoon: 'coming soon',
    facetsTitle: 'Filtra',
    closeFacets: 'Chiudi',
    footerButtonOverlay: 'Applica',
    priceDisclaimer: '*Sconto applicato sul prezzo stagionale',
    colors: {
      Yellow: 'GIALLO',
      White: 'BIANCO',
      Teal: 'TURCHESE',
      Red: 'ROSSO',
      Purple: 'PORPORA',
      Pink: 'ROSA',
      Orange: 'ARANCIONE',
      Olive: 'OLIVA',
      Navy: 'BLU MARINO',
      Maroon: 'GRANATA CHIARO',
      Magenta: 'FUCSIA',
      Green: 'VERDE',
      Gray: 'GRIGIO',
      Brown: 'MARRONE',
      Blue: 'AZZURRO',
      Black: 'NERO',
      Beige: 'BEIGE'
    }
  },
  facets: {
    clearFilters: 'Cancella tutto',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Mostra di meno',
    showMore: 'Mostra più filtri ({filtersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filtra</span>' +
          '<span class="ebx-filters-text__overlay">Accetta</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtra</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }, groups: {}
    },
    titles: { hierarchical_category: 'categorie', eb_colors_facet: 'Colore', size_facet: 'Taglia' },
    values: {}
  },
  history: { title: 'Ricerche recenti', enable: 'Attiva', disable: 'Attiva', clear: 'Elimina' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Può interessarti' },
  nextQueries: {
    titleHTML: 'Potrebbe interessarti',
    nextQuery: {
      titleHTML: 'Altri clienti hanno cercato: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Vedi di più'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Purtroppo non ci sono risultati per la tua ricerca "</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">", ti mostriamo gli articoli più ricercati di oggi:</span>'
  },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Non ci sono risultati per </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, potrebbero interessarti:</span>',
    viewAllHTML: 'Vedi tutti {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Vedi risultati simili</span>`,
  },
  promotedLabel: '',
  popularSearches: { title: 'Ricerche popolari', inCategory: 'in' },
  recommendations: { title: 'Potrebbe interessarti', showAll: 'Vedi tutti' },
  results: { addToCart: 'Aggiungi', identifierTitle: '' },
  resultsSuggestions: { title: 'I prodotti più ricercati' },
  resultsFoundHTML: 'Risultati della ricerca. {resultsNumber} risultati',
  search: { inputPlaceholderAlternativesHTML: ['Cerca prodotti'] },
  selectedFilters: { title: 'Filtri selezionati {{count}}' },
  sort: { titleHTML: 'Ordina', sortTitles: { default: 'rilevanza', priceAsc: 'Prezzo crescente', priceDesc: 'Prezzo decrescente' } },
  spellcheck: 'Non ci sono risultati per \'{query}\'. Ecco i risultati per \'{spellcheck}\'',
  suggestions: { title: 'I più ricercati', inCategory: 'in' }
};
