/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const zh: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Sort'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">筛选</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">筛选</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Delete all'
  },
  history: {
    clear: '删除近期搜索'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">抱歉，我们没有找到符合您的搜索条件"</span>
<span class="ebx-no-results__message-query"> \"{query}\"</span> 的商品， 我们为您显示今天的热门款式：`,
    recommendations: {
      title: '您可能感兴趣'
    }
  },
  partialResult: {
    messageHTML: `<span class="ebx-partials__message-apologies">我们很抱歉！ 我们无法找到任何结果</span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, 但是我们发现了这些结果:</span>`,
    viewAllHTML: '查看所有结果  <span class="ebx-partial-result__view-all-count">({totalResults})</span>'
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">搜索结果。</span>&nbsp;<span class="ebx-results-number__count">{resultsNumber}</span> 个结果',
  search: {
    clear: '删除'
  },
  sort: {
    title: '排序'
  }
};
