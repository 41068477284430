/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const fr: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'Afficher' },
  custom: {
    mobileFacetsHeader: 'Filtres',
    headerTitle: 'Résultats',
    comingSoon: 'bientôt',
    facetsTitle: 'Filtrer',
    closeFacets: 'Fermer',
    footerButtonOverlay: 'Appliquer',
    priceDisclaimer: '* Remise appliquée à partir du prix saisonnier',
    colors: {
      Yellow: 'JAUNE',
      White: 'BLANC',
      Teal: 'TURQUOISE',
      Red: 'ROUGE',
      Purple: 'POURPRE',
      Pink: 'ROSE',
      Orange: 'ORANGE',
      Olive: 'OLIVE',
      Navy: 'BLEU MARINE',
      Maroon: 'GRENAT',
      Magenta: 'FUCHSIA',
      Green: 'VERT',
      Gray: 'GRIS',
      Brown: 'MARRON',
      Blue: 'BLEU',
      Black: 'NOIR',
      Beige: 'BEIGE'
    }
  },
  facets: {
    clearFilters: 'Effacer tout',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'Afficher moins',
    showMore: 'Afficher plus de filtres ({filtersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filter</span>' +
          '<span class="ebx-filters-text__overlay">Accept</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filter</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'Catégorie', eb_colors_facet: 'Couleur', size_facet: 'Taille' }
  },
  history: { title: 'Recherches récentes', enable: 'Activer', disable: 'Désactiver', clear: 'Nettoyer' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'Vous pourriez aimer' },
  nextQueries: {
    titleHTML: 'Vous pourriez aimer',
    nextQuery: {
      titleHTML: 'D’autres clients ont recherché: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Afficher plus'
    }
  },
  noResults: { messageHTML: '<span class="ebx-no-results__message-apologies">Malheureusement, nous n\'avons pas trouvé de résultat à votre recherche "</span> <span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-try-again">", nous vous montrons les articles plus prisés du jour:</span>' },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-apologies">Aucun résultat pour</span><span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, vous pourriez aimer :</span>',
    viewAllHTML: 'Afficher tout {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Afficher résultats semblables</span>`,
  },
  promotedLabel: '',
  popularSearches: { title: 'Recherches populaires', inCategory: 'dans' },
  recommendations: { title: 'Vous pourriez aussi aimer', showAll: 'Afficher tout' },
  results: { addToCart: 'Ajouter', identifierTitle: '' },
  resultsSuggestions: { title: 'Articles les plus prisés' },
  resultsFoundHTML: 'Résultats de recherche. {resultsNumber} résultats',
  search: { inputPlaceholderAlternativesHTML: ['Chercher des articles'] },
  selectedFilters: { title: 'Filtres sélectionnés ({count})' },
  sort: { titleHTML: 'Classer', sortTitles: { default: 'Pertinence', priceAsc: 'Prix croissant', priceDesc: 'Prix décroissant' } },
  spellcheck: 'Nous n\'avons pas trouvé de résultats pour \'{query}\'. Voici les résultats pour \'{spellcheck}\'',
  suggestions: { title: 'Les plus prisés', inCategory: 'dans' }
};
