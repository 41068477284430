










import { Components } from '@empathybroker/ebx-gluon';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    CrossIcon: Components.Pure.CrossIcon,
    EventButton: Components.Pure.EventButton
  }
})
export default class CloseFacets extends Vue {

}
