









import { OyshoResultColor } from '@/adapter/oysho.models';
import OyshoTooltip from '@/components/add-to-cart/oysho-tooltip.vue';
import { VueCssClasses } from '@empathybroker/ebx-gluon';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { OyshoTooltip }
})
export default class OyshoColor extends Vue {
  @Prop({ required: true })
  color!: OyshoResultColor;

  @Prop({ default: false })
  isSelected!: boolean;

  get cssClasses(): VueCssClasses {
    return {
      'ebx-oysho-color--is-selected': this.isSelected
    };
  }

  emitClick(event: MouseEvent): void {
    this.$emit('click', this.color, event);
  }
}
