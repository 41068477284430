/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const ro: DeepPartial<Messages> = {
  close: {
    buttonText: ''
  },
  columnsPicker: {
    title: 'Vizualizare'
  },
  custom: {
    mobileFacetsHeader: 'Filtre',
    headerTitle: 'Rezultate',
    comingSoon: 'coming soon',
    facetsTitle: 'Filtrare',
    closeFacets: 'Închide',
    footerButtonOverlay: 'Aplicare',
    priceDisclaimer: '* Discount aplicat la prețul sezonier',
    colors: {
      Yellow: 'GALBEN',
      White: 'ALB',
      Teal: 'TURCOAZ',
      Red: 'ROȘU',
      Purple: 'PURPURIU',
      Pink: 'ROZ',
      Orange: 'PORTOCALIU',
      Olive: 'MĂSLINIU',
      Navy: 'BLEUMARIN',
      Maroon: 'GRENA',
      Magenta: 'FUCSIA',
      Green: 'VERDE',
      Gray: 'GRI',
      Brown: 'MARO',
      Blue: 'ALBASTRU',
      Black: 'NEGRU',
      Beige: 'BEJ'
    }
  },
  facets: {
    clearFilters: 'Șterge tot',
    defaultRange: {
      lessHTML: '< {max}',
      betweenHTML: '{min} - {max}',
      fromHTML: '> {min}'
    },
    defaultDynamicRange: {
      clear: '×',
      filter: '→'
    },
    showLess: 'Afișare mai puține',
    showMore: 'Afișare mai multe filtre ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">Filtrare</span>' +
          '<span class="ebx-filters-text__overlay">Acceptare</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrare</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      },
      groups: {}
    },
    titles: {
      hierarchical_category: 'Categorii',
      eb_colors_facet: 'Culoare',
      size_facet: 'Mărimea'
    },
    values: {}
  },
  history: {
    title: 'Căutări recente',
    enable: 'Activare',
    disable: 'Dezactivare',
    clear: 'Ștergere'
  },
  identifierSuggestions: {
    title: ''
  },
  nextQueriesSuggestions: {
    title: 'Te poate interesa'
  },
  nextQueries: {
    titleHTML: 'Te-ar putea interesa',
    nextQuery: {
      titleHTML: 'Alți clienți au căutat: <span class="ebx-next-query__title-query">{query}</span> <span class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: 'Vezi mai multe'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">Din păcate, nu am găsit nimic care să se potrivească căutării tale </span><span class="ebx-no-results__message-query">„{query}”</span><span class="ebx-no-results__message-try-again">, îți arătăm cele mai căutate articole de astăzi:</span>'
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Nu există rezultate pentru </span> <span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-try-again">, poate te-ar interesa:</span>',
    viewAllHTML: 'Vizualizare toate <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">Afișare rezultate similare</span>`
  },
  promotedLabel: '',
  popularSearches: {
    title: 'Căutări populare',
    inCategory: 'în'
  },
  recommendations: {
    title: 'Poate te interesează',
    showAll: 'Vizualizare toate'
  },
  results: {
    addToCart: 'Adăuga',
    identifierTitle: ''
  },
  resultsSuggestions: {
    title: 'Produsele cele mai căutate'
  },
  resultsFoundHTML: 'Rezultatele căutării. {resultsNumber} rezultate',
  search: {
    inputPlaceholderAlternativesHTML: ['Căutare produse']
  },
  selectedFilters: {
    title: 'Filtre selectate ({count})'
  },
  sort: {
    titleHTML: 'Ordonare',
    sortTitles: {
      default: 'Relevanţă',
      priceAsc: 'Preț ascendent',
      priceDesc: 'Preț scăzut'
    }
  },
  spellcheck: 'Nu am găsit rezultate pentru \'{query}\'. Îți afișăm rezultatele pentru \'{spellcheck}\'',
  suggestions: {
    title: 'Cele mai căutate',
    inCategory: 'în'
  }
};
