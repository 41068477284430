/* tslint:disable:max-line-length */
import { DeepPartial } from '@empathybroker/ebx-gluon';

export const sk: DeepPartial<any> = {
  custom: {
    mobileFacetsHeader: 'Filtre'
  },
  facets: {
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">Filtrovať</span><span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">Filtrovať</span><span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    clearFilters: 'Delete all'
  },
  history: {
    clear: 'Vymazať'
  },
  noResults: {
    messageHTML: `<span class="ebx-no-results__message-apologies">Bohužiaľ, nenašli sme nič, čo by vyhovovalo vyhľadávaniu </span><span class="ebx-no-results__message-query">"{query}"</span><span class="ebx-no-results__message-try-again">, ukážeme ti, čo sa v súčasnosti najviac vyhľadáva:</span>`,
    recommendations: {
      title: 'Mohlo by ťa zaujímať'
    }
  },
  partialResult: {
    messageHTML: '<span class="ebx-partials__message-apologies">Žiadne výsledky pre </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">, možno ťa zaujme niečo z tohto:</span>',
    viewAllHTML: 'Zobraziť všetko <span class="ebx-partial-result__view-all-count">({totalResults})</span>',
  },
  resultsFoundHTML: '<span class="ebx-results-number__title">Výsledky vyhľadávania. </span>&nbsp;<span class="ebx-results-number__count">({resultsNumber})</span> výsledky',
  search: {
    clear: 'Vymazať'
  },
  sort: {
    title: 'Zoradiť'
  }
};
