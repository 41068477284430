import { MultiSelect } from '@empathy/search-types';
import { COMPONENTS, DeepPartial, FacetsConfig, SLOTS } from '@empathybroker/ebx-gluon';

export const MOBILE_FACETS_CONFIG: DeepPartial<FacetsConfig> = {
  default: {
    facet: {
      startCollapsed: true,
      showMoreButton: true,
      filtersToShow: 4,
      components: {
        icon: null,
        filter: {
          checkbox: COMPONENTS.PURE.CheckIcon
        },
        selectedFilters: {
          filterTitle: 'SelectedFilterTitle'
        }
      }
    }
  },
  facets: {
    eb_colors_facet: {
      mapping: {
        multiSelectable: MultiSelect.Disabled
      },
      components: {
        filter: {
          title: 'OyshoFilterTitle'
        }
      }
    }
  },
  groups: {
    'all-facets': {
      slidingDirection: 'right',
      addRemainingFacets: false,
      templateSlots: {
        panel: SLOTS.template.overlay
      },
      components: {
        selectedFilters: {
          selectedFilters: COMPONENTS.PURE.SelectedFilters,
          afterSelectedFilters: COMPONENTS.SMART.ClearSelectedFiltersByGroup
        },
        header: 'FacetsGroupHeader',
        clearIcon: COMPONENTS.PURE.CrossIcon,
        toggleIcon: 'FilterToggleIcon'
      },
      slots: {
        close: SLOTS.facetsGroup.footer,
        clear: null,
        selectedFilters: SLOTS.facetsGroup.footer
      },
      facets: {
        eb_colors_facet: {
          collapsible: true,
          showMoreButton: false,
          components: {
            filter: {
              checkbox: 'ColorFilter'
            },
            icon: 'OyshoCaretDownIcon'
          }
        },
        hierarchical_category: {
          collapsible: true,
          startCollapsed: true,
          showMoreButton: false,
          components: {
            filter: {
              title: 'OyshoHierarchicalTitle',
              checkbox: null
            },
            icon: 'OyshoCaretDownIcon'
          }
        }
      }
    }
  }
};

export const DESKTOP_FACETS_CONFIG: DeepPartial<FacetsConfig> = {
  facets: {
    eb_colors_facet: {
      mapping: {
        multiSelectable: MultiSelect.Disabled
      }
    }
  },
  groups: {
    'hierarchical-category-facet': {
      collapsible: false,
      startCollapsed: false,
      templateSlots: {
        panel: SLOTS.template.leftAside
      },
      components: {
        selectedFilters: {
          selectedFilters: null
        },
        footer: null,
        toggleIcon: COMPONENTS.PURE.ChevronLeftIcon,
        clear: null
      },
      slots: {
        clear: SLOTS.facetsGroup.header,
        selectedFilters: SLOTS.facetsGroup.header
      },
      facets: {
        hierarchical_category: {
          collapsible: false,
          startCollapsed: false,
          showMoreButton: false,
          components: {
            filter: {
              title: 'OyshoHierarchicalTitle',
              checkbox: null
            }
          }
        }
      }
    },
    'all-facets': {
      collapsible: true,
      startCollapsed: true,
      slidingDirection: 'right',
      templateSlots: {
        panel: SLOTS.template.overlay,
        toggle: SLOTS.template.toolbar
      },
      components: {
        header: 'OyshoSortList',
        selectedFilters: {
          selectedFilters: null
        },
        footer: 'ApplyFacetsGroup',
        toggleIcon: 'FilterToggleIcon',
        clear: COMPONENTS.PURE.ClearFacetsFilters,
        panel: COMPONENTS.PANELS.OverlappingPanel,
        close: 'CloseFacets'
      },
      slots: {
        close: SLOTS.facetsGroup.header,
        clear: SLOTS.facetsGroup.header
      },
      facets: {
        eb_colors_facet: {
          collapsible: true,
          startCollapsed: true,
          showMoreButton: false,
          components: {
            filter: {
              checkbox: 'ColorFilter'
            },
            icon: 'OyshoCaretDownIcon'
          }
        }
      }
    }
  }
};
