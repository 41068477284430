/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const ar: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: 'مشاهدة' },
  custom: {
    mobileFacetsHeader: 'مرشحات',
    headerTitle: 'نتائج البحث',
    comingSoon: 'قريبًا',
    facetsTitle: 'ترشيح',
    closeFacets: 'إغلاق',
    footerButtonOverlay: 'تطبيق',
    priceDisclaimer: '* يُطبق الخصم على السعر الموسمي',
    colors: {
      Yellow: 'الأصفر',
      White: 'أبيض',
      Teal: 'بط نهري صغير',
      Red: 'أحمر',
      Purple: 'أرجواني',
      Pink: 'زهري',
      Orange: 'البرتقالي',
      Olive: 'زيتون',
      Navy: 'القوات البحرية',
      Maroon: 'مارون',
      Magenta: 'أرجواني',
      Green: 'أخضر',
      Gray: 'اللون الرمادي',
      Brown: 'بنى',
      Blue: 'أزرق',
      Black: 'أسود',
      Beige: 'اللون البيج'
    }
  },
  facets: {
    clearFilters: 'مسح الكل',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: 'عرض الأقل',
    showMore: 'عرض المزيد من المرشحات ({hiddenFiltersNumber})',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">الفلاتر</span>' +
          '<span class="ebx-filters-text__overlay">قبول</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">الفلاتر</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      }
    },
    titles: { hierarchical_category: 'الفئات', eb_colors_facet: 'اللون', size_facet: 'بحجم' },
    values: {}
  },
  history: {
    title: 'عمليات البحث الأخيرة',
    enable: 'تفعيل',
    disable: 'إلغاء التفعيل',
    clear: 'مسح العناصر الأخيرة'
  },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: 'من الممكن أن يهمك' },
  nextQueries: {
    titleHTML: 'قد يهمك',
    nextQuery: {
      titleHTML: '<span class="ebx-next-query__title-query">{query}</span> ' +
        '<span class="ebx-next-query__title-count">({totalResults})</span>'
      + 'زبونات أخريات بحثن عن:',
      viewAllHTML: 'مشاهدة المزيد'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-try-again">، نعرض عليك أكثر ما تم البحث عنه اليوم: </span><span class="ebx-no-results__message-query">{query}</span><span class="ebx-no-results__message-apologies"> للأسف لم نجد شيئًا يتوافق مع بحثك عن "</span>'
  },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: `<span class="ebx-partials__message-apologies">لا توجد نتائج ل </span><span class="ebx-partials__message-query">"{query}"</span><span class="ebx-partials__message-try-again">، قد تهمك المنتجات التالية:</span>`,
    viewAllHTML: 'مشاهدة الكل {totalResults}',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">مشاهدة نتائج مشابهة</span>`,
  },
  popularSearches: { title: 'عمليات البحث الأكثر شعبية', inCategory: 'في' },
  promotedLabel: '',
  recommendations: { title: 'قد يهمّك', showAll: 'مشاهدة الكل' },
  results: { addToCart: 'إضافة إلى سلة التسوق', identifierTitle: '' },
  resultsSuggestions: { title: 'المنتجات الأكثر طلبًا' },
  resultsFoundHTML: '<span class="ebx-results-number__title">نتائج البحث.</span>&nbsp;<span class="ebx-results-number__count">{resultsNumber} نتائج</span>',
  search: { inputPlaceholderAlternativesHTML: ['البحث عن منتجات'] },
  selectedFilters: { title: 'المرشحات المختارة ({count})' },
  spellcheck: 'لم نجد نتائج لـ\'{query}\'. إبراز النتائج لـ\'{spellcheck}\'',
  sort: {
    titleHTML: 'الترتيب',
    sortTitles: { default: 'الصلةملاءمة', priceAsc: 'السعر تصاعديًاتصاعدي', priceDesc: 'السعر تنازليًاتنازلي' }
  },
  suggestions: { title: 'الأكثر طلبًا', inCategory: 'في' }
};
