/* tslint:disable:max-line-length */
import { DeepPartial, Messages } from '@empathybroker/ebx-gluon';

export const ko: DeepPartial<Messages> = {
  close: { buttonText: '' },
  columnsPicker: { title: '보기' },
  custom: {
    mobileFacetsHeader: '필터',
    headerTitle: '결과',
    comingSoon: 'coming soon',
    facetsTitle: '필터',
    closeFacets: '닫기',
    footerButtonOverlay: '적용',
    priceDisclaimer: '*시즌 정가에 할인 적용'
  },
  facets: {
    clearFilters: '모두 지우기',
    defaultRange: { lessHTML: '< {max}', betweenHTML: '{min} - {max}', fromHTML: '> {min}' },
    defaultDynamicRange: { clear: '×', filter: '→' },
    showLess: '간략히 표시',
    showMore: '({filtersNumber}) 개의 필터 더 보기',
    toggle: {
      default: {
        closeHTML: '<span class="ebx-filters-text">' +
          '<span class="ebx-filters-text__footer">개의 필터</span>' +
          '<span class="ebx-filters-text__overlay">확인</span>' +
          '</span>' +
          '&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>',
        openHTML: '<span class="ebx-filters-text">개의 필터</span>&nbsp;<span class="ebx-filters-count">{selectedFiltersCount}</span>'
      },
      groups: {}
    },
    titles: { hierarchical_category: '카테고리', eb_colors_facet: '색상', size_facet: '크기' },
    values: {}
  },
  history: { title: '최근 검색', enable: '활성화', disable: '비활성화', clear: '지우기/ 최신 지우기' },
  identifierSuggestions: { title: '' },
  nextQueriesSuggestions: { title: '관심 제품' },
  nextQueries: {
    titleHTML: '관심 제품',
    nextQuery: {
      titleHTML: '관심 가지실만한 제품: <span' +
        ' class="ebx-next-query__title-query">{query}</span> <span' +
        ' class="ebx-next-query__title-count">({totalResults})</span>',
      viewAllHTML: '더보기'
    }
  },
  noResults: {
    messageHTML: '<span class="ebx-no-results__message-apologies">죄송합니다. "</span><span class="ebx-no-results__message-query"> {query}</span><span class="ebx-no-results__message-try-again">"검색과 일치하는 항목을 찾지 못했습니다. 오늘 가장 많이 검색된 항목이 표시됩니다.</span>'
  },
  partialResult: {
    titleHTML: `<span class="ebx-partial-result__query">{query}</span><span class="ebx-partial-result__count"> ({totalResults})</span>`,
    messageHTML: '<span class="ebx-partials__message-query">{query}</span><span class="ebx-partials__message-apologies">에 대한 결과가 없습니다. </span><span class="ebx-partials__message-try-again">다음과 같은 항목에 관심 있으실 수 있습니다.</span>',
    viewAllHTML: '{totalResults} 개 모두 보기',
    partialsWithResultsMessageHTML: `<span class="ebx-partials__message-low-results">비슷한 결과 보기</span>`
  },
  promotedLabel: '',
  popularSearches: { title: '가장 많은 검색', inCategory: '에' },
  recommendations: { title: '관심 있을 제품', showAll: '모두 보기' },
  results: { addToCart: '추가', identifierTitle: '' },
  resultsSuggestions: { title: '가장 많이 찾으시는 제품' },
  resultsFoundHTML: '검색 결과. {resultsNumber} 결과',
  search: { inputPlaceholderAlternativesHTML: ['제품 검색'] },
  selectedFilters: { title: '({count}) 개의 선택된 필터' },
  sort: { titleHTML: '순서', sortTitles: { default: '관련성', priceAsc: '가격 오름차순', priceDesc: '가격 인하' } },
  spellcheck: '\'{query}\'에 대한 결과가 없습니다. \'{spellcheck}\'에 대한 결과를 표시합니다.',
  suggestions: { title: '가장 많이 찾으시는 상품', inCategory: '에' }
};
