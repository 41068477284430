import { DeepPartial, TrackAddToCartConfig } from '@empathybroker/ebx-gluon';

export const MOBILE_ADD_TO_CART_CONFIG: DeepPartial<TrackAddToCartConfig> = {
  components: {
    addToCartOnProductPage: 'OyshoAdd2CartOnProductPage'
  },
  buttonsSelector: '.sizes',
  storage: {
    resultKeyField: 'productId'
  }
};

export const DESKTOP_ADD_TO_CART_CONFIG: DeepPartial<TrackAddToCartConfig> = {
  buttonsSelector: '.addToCartMode > #button-add-sizes, .fast-shop-bar__elem--green'
};
