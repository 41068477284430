import { DESKTOP_ADD_TO_CART_CONFIG, MOBILE_ADD_TO_CART_CONFIG } from '@/config/add-to-cart.config';
import { BANNER_CONFIG } from '@/config/banner.config';
import { DESKTOP_CLOSE_CONFIG, MOBILE_CLOSE_CONFIG } from '@/config/close.config';
import { rebindContainer } from '@/config/configurator/rebind-container.configurator';
import { registerComponents } from '@/config/configurator/register-components.configurator';
import { DESKTOP_CORE_CONFIG, MOBILE_CORE_CONFIG } from '@/config/core.config';
import { DYNAMIC_CONFIG } from '@/config/dynamic.config';
import { DESKTOP_EMPATHIZE_CONFIG, MOBILE_EMPATHIZE_CONFIG } from '@/config/empathize.config';
import { DESKTOP_FACETS_CONFIG, MOBILE_FACETS_CONFIG } from '@/config/facets.config';
import { IDENTIFIER_SUGGESTIONS } from '@/config/identifier-suggestions.config';
import { OYSHO_CONFIG } from '@/config/inditex.config';
import { MOBILE_QUERIES_SUGGESTIONS_CONFIG } from '@/config/next-queries-suggestions.config';
import { DESKTOP_NEXT_QUERY_CONFIG, MOBILE_NEXT_QUERY_CONFIG } from '@/config/next-queries.config';
import OyshoEmpathyXApi from '@/config/oysho-empathy-x-api';
import {
  DESKTOP_PARTIALS_CONFIG,
  MOBILE_PARTIAL_RESULT_CONFIG,
  MOBILE_PARTIALS_CONFIG
} from '@/config/partials.config';
import { POPULAR_SEARCHES_CONFIG } from '@/config/popular-searches.config';
import { DESKTOP_RECOMMENDATIONS_CONFIG, MOBILE_RECOMMENDATIONS_CONFIG } from '@/config/recommendations.config';
import { DESKTOP_RELATED_TAGS_CONFIG, MOBILE_RELATED_TAGS_CONFIG } from '@/config/related-tags.config';
import { DESKTOP_RESULT_CONFIG, IDENTIFIER_RESULT_CONFIG, MOBILE_RESULT_CONFIG } from '@/config/result.config';
import { DESKTOP_RESULTS_GRID_CONFIG, MOBILE_RESULTS_GRID_CONFIG } from '@/config/results-grid.config';
import { MOBILE_SCROLL_TO_TOP_CONFIG } from '@/config/scroll-to-top.config';
import { DESKTOP_SEARCHBOX_CONFIG, MOBILE_SEARCHBOX_CONFIG } from '@/config/search-box.config';
import { DESKTOP_SORT_CONFIG, MOBILE_SORT_CONFIG } from '@/config/sort.config';
import { STORE_CONFIG } from '@/config/store.config';
import { TERM_SUGGESTIONS_CONFIG } from '@/config/suggestions.config';
import { WIRING_CONFIG } from '@/config/wiring.config';
import { DESKTOP_LOCALE_CONFIG } from '@/i18n/desktop/locale.config';
import { MOBILE_LOCALE_CONFIG } from '@/i18n/mobile/locale.config';
import { inditexConfiguratorFactory } from '@empathy/inditex-utils';
import { container, installEmpathyX } from '@empathybroker/ebx-gluon';
import App from './app.vue';
import { HISTORY_CONFIG } from './config/history.config';

installEmpathyX(appBuilder => {
  window.EmpathyX = container.resolve(OyshoEmpathyXApi),
  appBuilder
    .setAppComponent(App)
    .addSubConfig(inditexConfiguratorFactory(OYSHO_CONFIG))
    .addSubConfig(WIRING_CONFIG)
    .overrideStore(STORE_CONFIG)
    .setDynamicConfig(DYNAMIC_CONFIG)
    .setCustomConfigurator(configuratorOptions => {
      registerComponents();
      rebindContainer(configuratorOptions);
    })
    .setConfigFor('mobile', configBuilder => {
      configBuilder
        .setUserServiceStorageConfig({
          userId: { key: 'empathy-x-id' },
          userType: { key: 'empathy-x-visit' }
        })
        .setOpenConfig({ triggersSelector: '#search, #buscador' })
        .setBannersConfig(BANNER_CONFIG)
        .setCoreConfig(MOBILE_CORE_CONFIG)
        .setCloseConfig(MOBILE_CLOSE_CONFIG)
        .setEmpathizeConfig(MOBILE_EMPATHIZE_CONFIG)
        .setFacetsConfig(MOBILE_FACETS_CONFIG)
        .setHistoryConfig(HISTORY_CONFIG)
        .setIdentifierSuggestionsConfig(IDENTIFIER_SUGGESTIONS)
        .setPopularSearchesConfig(POPULAR_SEARCHES_CONFIG)
        .setLocaleConfig(MOBILE_LOCALE_CONFIG)
        .setNextQueriesConfig(MOBILE_NEXT_QUERY_CONFIG)
        .setNextQueriesSuggestionsConfig(MOBILE_QUERIES_SUGGESTIONS_CONFIG)
        .setPartialsConfig(MOBILE_PARTIALS_CONFIG)
        .setResultsConfig(MOBILE_RESULT_CONFIG)
        .setResultsConfig(IDENTIFIER_RESULT_CONFIG, 'identifierSuggestions')
        .setResultsConfig(MOBILE_PARTIAL_RESULT_CONFIG, 'partials')
        .setResultsGridConfig(MOBILE_RESULTS_GRID_CONFIG)
        .setSearchBoxConfig(MOBILE_SEARCHBOX_CONFIG)
        .setSortConfig(MOBILE_SORT_CONFIG)
        .setRecommendationsConfig(MOBILE_RECOMMENDATIONS_CONFIG)
        .setRelatedTagsConfig(MOBILE_RELATED_TAGS_CONFIG)
        .setScrollToTopConfig(MOBILE_SCROLL_TO_TOP_CONFIG)
        .setSuggestionsConfig(TERM_SUGGESTIONS_CONFIG)
        .setTrackAddToCartConfig(MOBILE_ADD_TO_CART_CONFIG);
    })
    .setConfigFor('desktop', configBuilder => {
      configBuilder
        .setCoreConfig(DESKTOP_CORE_CONFIG)
        .setCloseConfig(DESKTOP_CLOSE_CONFIG)
        .setEmpathizeConfig(DESKTOP_EMPATHIZE_CONFIG)
        .setResultsGridConfig(DESKTOP_RESULTS_GRID_CONFIG)
        .setFacetsConfig(DESKTOP_FACETS_CONFIG)
        .setLocaleConfig(DESKTOP_LOCALE_CONFIG)
        .setNextQueriesConfig(DESKTOP_NEXT_QUERY_CONFIG)
        .setPartialsConfig(DESKTOP_PARTIALS_CONFIG)
        .setSearchBoxConfig(DESKTOP_SEARCHBOX_CONFIG)
        .setSortConfig(DESKTOP_SORT_CONFIG)
        .setRecommendationsConfig(DESKTOP_RECOMMENDATIONS_CONFIG)
        .setResultsConfig(DESKTOP_RESULT_CONFIG)
        .setResultsConfig(DESKTOP_RESULT_CONFIG, 'partials')
        .setTrackAddToCartConfig(DESKTOP_ADD_TO_CART_CONFIG)
        .setRelatedTagsConfig(DESKTOP_RELATED_TAGS_CONFIG);
    });
});
